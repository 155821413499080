import { FC } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import useBem from '@frontend/use-bem';
import useAvailableSurveys from '../../../../Hooks/useAvailableSurveys';

const SurveysCell: FC<GridCellProps> = ({ dataItem }) => {
  const { bemClassName, bemClassNames } = useBem('default-cell');
  const { data, isLoading } = useAvailableSurveys();
  const { SurveyIds } = dataItem;

  const surveys = SurveyIds.map((survey: string) => data?.find(({ SurveyId }) => SurveyId === survey)?.SurveyName).join(
    ', ',
  );

  return (
    <td
      className={bemClassNames(['k-table-td', bemClassName()])}
      colSpan={1}
    >
      {!isLoading && surveys}
    </td>
  );
};

export default SurveysCell;
