import { useParams } from 'react-router-dom';
import Level from '../../../../Shared/Enums/Level';

export type PropType = {
  gridLayer?: Level;
};

const getFilterLevel = ({ gridLayer }: PropType) => {
  const { customerId, organisationId, divisionId, businessUnitId, unitId, teamId } = useParams();

  if (gridLayer) {
    switch (gridLayer) {
      case Level.ADMIN:
        return null;
      case Level.CUSTOMER:
        return {
          logic: 'and',
          filters: [{ field: 'CustomerId', operator: 'eq', value: `guid${customerId}guid` }],
        };
        break;
      case Level.ORGANISATION:
        return {
          logic: 'and',
          filters: [{ field: 'OrganisationId', operator: 'eq', value: `guid${organisationId}guid` }],
        };
        break;
      case Level.DIVISION:
        return {
          logic: 'and',
          filters: [{ field: 'DivisionId', operator: 'eq', value: `guid${divisionId}guid` }],
        };
        break;
      case Level.BUSINESS_UNIT:
        return {
          logic: 'and',
          filters: [{ field: 'BusinessUnitId', operator: 'eq', value: `guid${businessUnitId}guid` }],
        };
        break;
      case Level.UNIT:
        return {
          logic: 'and',
          filters: [{ field: 'UnitId', operator: 'eq', value: `guid${unitId}guid` }],
        };
        break;
      case Level.USERS:
        return {
          logic: 'and',
          filters: [{ field: 'TeamId', operator: 'eq', value: `guid${teamId}guid` }],
        };
        break;
      default:
        break;
    }
  }

  return undefined;
};

export default getFilterLevel;
