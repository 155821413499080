import classNames from 'classnames';

import StatBlockTheme from '../../Enums/StatBlockTheme';
import Loader from '../Loader/Loader';

import './StatBlock.scss';

type PropType = {
  description: string;
  title: string;
  subtitle: string;
  theme: StatBlockTheme;
  isLoading: boolean;
  dataPresent: boolean;
};

const TextBlockBig = ({ dataPresent, description, title, subtitle, theme, isLoading }: PropType) => (
  <div className={classNames('stat-block', `stat-block--${theme}`)}>
    {!isLoading && (
      <div className="stat-block__content">
        {dataPresent && (
          <>
            <span>{subtitle}</span>
            <h3>{title}</h3>

            <p>{description}</p>
          </>
        )}
      </div>
    )}
    {isLoading && (
      <div className="loader-position">
        <Loader white />
      </div>
    )}
  </div>
);

export default TextBlockBig;
