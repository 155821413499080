import IStatistic from '../Interfaces/IStatistic';
import SurveyText from '../Survey/SurveyText';
import Domain from './Domain';
import Statistic from './Statistic';

class SurveyScore {
  responseCount: number;

  userCount: number;

  responsePercentage: number;

  domains: Domain[];

  lowest: IStatistic;

  highest: IStatistic;

  constructor(
    responseCount: number,
    userCount: number,
    lowest: IStatistic,
    highest: IStatistic,
    domainScores: Domain[],
  ) {
    this.responseCount = responseCount;
    this.userCount = userCount;
    this.lowest = lowest;
    this.highest = highest;
    this.domains = domainScores;
    this.responsePercentage = (responseCount / userCount) * 100;
  }

  public static parse(object: any) {
    return new SurveyScore(
      object.ResponseCount,
      object.UserCount,
      Statistic.parse(object.SurveyCalculationStatistics.LowestDomain),
      Statistic.parse(object.SurveyCalculationStatistics.HighestDomain),
      object.DomainScores.map((domain: {}) => Domain.parse(domain)),
    );
  }

  public getRadarChartValues() {
    const domainScores: any[] = [];
    this.domains.forEach((score) => {
      domainScores.push(score.domainScore.value);
    });

    return domainScores;
  }

  public getDomainLabel(key: string) {
    let label: SurveyText[] = [];
    this.domains.forEach((domain) => {
      if (domain.domainScore.key === key) {
        label = domain.domainLabel;
      }
    });

    return label;
  }
}

export default SurveyScore;
