import FilterOption from '../../Models/Dashboard/FilterOption';
import http from '../../Utils/HttpClient';

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const getAllTeams = async () => {
  const { data } = (await http.get(`${base}/Teams`)) as any;

  if (!data.value) return null;

  return data.value.map(FilterOption.parse);
};

export default getAllTeams;
