import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@frontend/use-bem';
import { useNavigate } from 'react-router-dom';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import Footer from '../../../Shared/Components/Footer/FooterRow';

const SignupSuccessfulPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.__RUNTIME_CONFIG__.REACT_APP_CLIENT !== 'tno') {
      navigate('/');
    }
  }, []);

  const { bemClassName } = useBem('user-settings');

  return (
    <div className="full-height">
      <NavigationBar
        menuTitle=""
        showContentButtons={false}
      />
      <div className={bemClassName()}>
        <div className="content-container">
          <div className={bemClassName()}>
            <h1 className={bemClassName('title')}>{t('register.successful.title')}</h1>
            <p className={bemClassName('title')}>{t('register.successful.description')}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignupSuccessfulPage;
