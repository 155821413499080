import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getUnit } from '../Data/UnitService';

const useUnit = (unitId: string): UseQueryResult<FilterOption[], Error> =>
  useQuery(['Unit', unitId], () => getUnit(unitId), {
    staleTime: undefined,
  });

export default useUnit;
