import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../Shared/Components/Loader/Loader';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import Level from '../../../Shared/Enums/Level';
import useUser from '../../../Shared/Hooks/useUser';
import { SelectedGridEnum } from '../../../Shared/Components/DataGridSelector/types';
import ManagementPageHeader from './components/ManagementPageHeader/ManagementPageHeader';
import DataGridSelector from '../../../Shared/Components/DataGridSelector/DataGridSelector';
import useTeam from '../../Dashboard/Hooks/useTeam';
import UserDataGrid from '../../../Shared/Components/DataGrid/UserDataGrid';

import '../management.scss';

const UnitManagementLayer = () => {
  const { t } = useTranslation();
  const [selectedGrid, setSelectedGrid] = useState<SelectedGridEnum>(SelectedGridEnum.LinkedUsers);
  const { userInfo } = useUser();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const { isLoading, data: team } = useTeam(teamId!);

  if (isLoading) {
    return (
      <div className="loader-position">
        <Loader />
      </div>
    );
  }

  if (userInfo?.checkPermission('write:units:businessunit')) {
    return (
      <>
        <NavigationBar
          hasTitle
          hasBorder={false}
          menuTitle={t('dashboard.general.menu-title')}
          showProfile
        />
        <div className="content-container">
          <ManagementPageHeader
            title={team ? team[0].name : t('management.Divisions.title')}
            gridLayer={Level.USERS}
          />
          <DataGridSelector
            selectedGrid={selectedGrid}
            onGridSelect={(grid) => setSelectedGrid(grid)}
            gridLayer={Level.USERS}
            disableSubLayers
          />
          <UserDataGrid level={Level.USERS} />
        </div>
      </>
    );
  }

  if (userInfo) {
    navigate('/dashboard');
  }

  return (
    <div className="loader-position">
      <Loader />
    </div>
  );
};

export default UnitManagementLayer;
