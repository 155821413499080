import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getBusinessUnit } from '../Data/BusinessUnitService';

const useBusinessUnit = (businessUnitId: string): UseQueryResult<FilterOption[], Error> =>
  useQuery(['BusinessUnit', businessUnitId], () => getBusinessUnit(businessUnitId), {
    staleTime: undefined,
  });

export default useBusinessUnit;
