import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFilledInSurveyData } from '../../../Store/SurveyContext';
import SurveyPostAnswer from '../../../Models/Survey/SurveyPostAnswer';
import SurveyRender from './SurveyRender';
import { postPublic } from '../Data/SurveyService';
import SurveyPostPublic from '../../../Models/Survey/SurveyPostPublic';

type PropType = {
  forceWellbeing?: boolean;
  forceBackground?: boolean;
};

const SurveyPage = ({ forceWellbeing = false, forceBackground = false }: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const navigate = useNavigate();

  const [questionNumber, setQuestionNumber] = useState<number>(0);
  const [showIntro, setShowIntro] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { questionAnswers, setQuestionAnswers, surveyInformation } = useFilledInSurveyData();
  const { id } = useParams();

  const decodedInfo: { SurveyId: string; TeamId: string } = JSON.parse(atob(id!));
  const { SurveyId, TeamId } = decodedInfo;

  const buildSurveySubmit = () => {
    const parsedAnswers = questionAnswers.map(SurveyPostAnswer.parse);

    const surveyPost: SurveyPostPublic = {
      TeamId,
      UserResponseLanguage: 'en',
      Answers: parsedAnswers,
    };

    return surveyPost;
  };

  const showMenu = forceWellbeing || forceBackground;

  const handleNext = async () => {
    setShowIntro(false);

    if (questionNumber < surveyInformation.totalQuestions || surveyInformation.totalQuestions === 0) {
      setQuestionNumber(questionNumber + 1);
    } else if (questionNumber === surveyInformation.totalQuestions) {
      setIsSubmitting(true);
      await postPublic(surveyInformation.surveyId, buildSurveySubmit());
      setQuestionAnswers(undefined);
      setIsSubmitting(false);
      navigate('/survey/public/completed');
    }
  };

  const handlePrevious = () => {
    if (questionNumber >= 1) {
      setQuestionNumber(questionNumber - 1);
      if (questionNumber - 1 === 0) {
        setShowIntro(true);
      }
    }
  };

  const showLanguageSelectDuringSurvey = !(questionNumber !== 0 && client === 'tno');

  return (
    <SurveyRender
      surveyId={SurveyId}
      questionNumber={questionNumber}
      showMenu={showMenu}
      showLanguageSelectDuringSurvey={showLanguageSelectDuringSurvey}
      handleNext={() => handleNext()}
      handlePrevious={() => handlePrevious()}
      showIntro={showIntro}
      isPublic
      isSubmitting={isSubmitting}
    />
  );
};

export default SurveyPage;
