import http from '../../Utils/HttpClient';

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const getUser = async (userId: string) => {
  const { data } = await http.get(`${base}/Users/${userId}`);

  return data;
};

const updateUser = async (userId: string, user: any) => {
  const { data } = await http.patch(`${base}/Users/${userId}`, user);

  return data;
};

const activateUser = async (userId: string) => {
  const { data } = await http.patch(`${base}/Users/${userId}/Activate`);

  return data;
};

const deactivateUser = async (userId: string) => {
  const { data } = await http.patch(`${base}/Users/${userId}/Deactivate`);

  return data;
};

const getUserByAuth0Id = async (auth0Id: string) => {
  const { data } = await http.get(`${base}/Users/${auth0Id}/GetByAuth0Id`);

  return data;
};

export { getUser, getUserByAuth0Id, activateUser, deactivateUser, updateUser };
