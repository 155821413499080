import FilterOption from '../../../Models/Dashboard/FilterOption';
import http from '../../../Utils/HttpClient';
import Customer from '../../../Models/Customer/Customer';

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const getCustomer = async (customerId: string) => {
  const { data } = (await http.get(`${base}/Customers/${customerId}`)) as any;

  return [FilterOption.parse(data)];
};

const getCustomerById = async (customerId: string) => {
  const { data } = (await http.get(`${base}/Customers/${customerId}`)) as any;

  return Customer.parse(data);
};

const getAllCustomers = async () => {
  const { data } = (await http.get(`${base}/Customers`)) as any;

  return data.value.map(FilterOption.parse);
};

export { getCustomer, getCustomerById, getAllCustomers };
