import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getAllBusinessUnits } from '../Data/BusinessUnitService';

const useBusinessUnits = (): UseQueryResult<FilterOption[], Error> =>
  useQuery(['BusinessUnits'], () => getAllBusinessUnits(), {
    staleTime: undefined,
  });

export default useBusinessUnits;
