import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getAllOrganisations } from '../Data/OrganisationService';

const useOrganisations = (): UseQueryResult<FilterOption[], Error> =>
  useQuery(['organisations'], () => getAllOrganisations(), {
    staleTime: undefined,
  });

export default useOrganisations;
