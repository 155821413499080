import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useUser from '../../../../Shared/Hooks/useUser';

type PropType = {
  title: string;
  wideHeader: boolean;
};

const DashboardTitle = ({ title, wideHeader }: PropType) => {
  const { t } = useTranslation();
  const { userInfo } = useUser();

  const hasName = userInfo?.nickname;

  return (
    <h1 className={classNames('dashboard-header__title', { 'dashboard-header__title--wide': wideHeader })}>
      {hasName && <span>{t('dashboard.greeting', { username: userInfo?.nickname })}</span>}
      {!hasName && <span>{t('dashboard.greeting-no-name')}</span>}
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </h1>
  );
};

export default DashboardTitle;
