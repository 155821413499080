import SurveyAvailability from '../../../Models/Survey/SurveyAvailability';
import http from '../../../Utils/HttpClient';

const base = `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_SURVEYS}/Surveys`;

const getAvailability = async () => {
  const { data } = await http.get(`${base}/Availability`);

  return data.value.map(SurveyAvailability.parse);
};

export default getAvailability;
