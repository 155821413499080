import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { SurveyFilledInDataProvider } from './Store/SurveyContext';
import { FilterFilledInDataProvider } from './Store/DashboardContext';

import './App.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <SurveyFilledInDataProvider>
      <FilterFilledInDataProvider>
        <div className="App">
          <Outlet />
        </div>
      </FilterFilledInDataProvider>
    </SurveyFilledInDataProvider>
  </QueryClientProvider>
);

export default App;
