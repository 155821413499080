import { useTranslation } from 'react-i18next';

import './FeedbackButton.scss';

const FeedbackButton = () => {
  const { t } = useTranslation();

  return (
    <a
      className="feedback-button"
      href={`
          mailto:
          ${window.__RUNTIME_CONFIG__.REACT_APP_FEEDBACK_EMAIL}
        `}
    >
      {t('generic.feedback')}
    </a>
  );
};

export default FeedbackButton;
