import SurveyQuestion from './SurveyQuestion';
import SurveyText from './SurveyText';
import SurveyChoiceQuestion from './SurveyChoiceQuestion';
import SurveyQuestionType from './SurveyQuestionType';
import SurveySliderQuestion from './SurveySliderQuestion';
import SurveyOpenQuestion from "./SurveyOpenQuestion";

class SurveySection {
  key: string;

  label?: string;

  questions: SurveyQuestion[];

  headingText: SurveyText[];

  subHeadingText: SurveyText[];

  explanationText: SurveyText[];

  constructor(
    key: string,
    label: string,
    questions: SurveyQuestion[],
    headingText: SurveyText[],
    subHeadingText: SurveyText[],
    explanationText: SurveyText[],
  ) {
    this.key = key;
    this.label = label;
    this.questions = questions;
    this.headingText = headingText;
    this.subHeadingText = subHeadingText;
    this.explanationText = explanationText;
  }

  public static parse(object: any) {
    return new SurveySection(
      object.Key,
      object.Label,
      object.Questions.map((question: any) => {
        switch (question.QuestionType) {
          case SurveyQuestionType.SINGLE_CHOICE:
          case SurveyQuestionType.MULTIPLE_CHOICE:
            return SurveyChoiceQuestion.parse(question);
          case SurveyQuestionType.SLIDER:
            return SurveySliderQuestion.parse(question);
          case SurveyQuestionType.OPEN:
            return SurveyOpenQuestion.parse(question);
          default:
            throw new Error('This type is not yet supported');
        }
      }),
      object.HeadingText.map((text: {}) => SurveyText.parse(text)),
      object.SubHeadingText.map((text: {}) => SurveyText.parse(text)),
      object.ExplanationText.map((text: {}) => SurveyText.parse(text)),
    );
  }
}

export default SurveySection;
