import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getAllDivisions } from '../Data/DivisionService';

const useDivisions = (): UseQueryResult<FilterOption[], Error> =>
  useQuery(['Divisions'], () => getAllDivisions(), {
    staleTime: undefined,
  });

export default useDivisions;
