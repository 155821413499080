import ISurvey from '../Interfaces/ISurvey';
import ISurveyResponse from '../Interfaces/ISurveyResponse';
import SurveyQuestion from './SurveyQuestion';
import SurveySpecification from './SurveySpecification';

class Survey implements ISurvey {
  id: string;

  active: boolean;

  createdDate: string;

  customerId: number;

  description: string;

  endDate: string;

  responses: ISurveyResponse[];

  serializedSpecification: SurveySpecification;

  startDate: string;

  title: string;

  constructor(
    id: string,
    active: boolean,
    createdDate: string,
    customerId: number,
    description: string,
    endDate: string,
    responses: ISurveyResponse[],
    serializedSpecification: SurveySpecification,
    startDate: string,
    title: string,
  ) {
    this.id = id;
    this.active = active;
    this.createdDate = createdDate;
    this.customerId = customerId;
    this.description = description;
    this.endDate = endDate;
    this.responses = responses;
    this.serializedSpecification = serializedSpecification;
    this.startDate = startDate;
    this.title = title;
  }

  public getQuestionCount() {
    let counter = 0;
    this.serializedSpecification.sections.forEach((section) => {
      counter += section.questions.length;
    });
    return counter;
  }

  public getAllQuestions() {
    const surveyQuestions: SurveyQuestion[] = [];

    this.serializedSpecification.sections.forEach((section) => {
      section.questions.forEach((question) => {
        surveyQuestions.push(question);
      });
    });

    return { ...surveyQuestions };
  }
}

export default Survey;
