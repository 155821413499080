import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FilterBar from './FilterBar/FilterBar';
import StatisticsRight from './StatisticsRight/StatisticsRight';
import ChartExplanation from './ChartExplanation/ChartExplanation';
import DomainOverview from '../DomainOverview/DomainOverview';
import { useFilledInFilterData } from '../../../../Store/DashboardContext';
import useStatisticsEmployee from '../../Hooks/useStatisticsEmployee';
import getLabelName from './Functions/LabelName';
import RadarChart from '../../../../Shared/Components/RadarChart/RadarChart';
import usePeriod from '../../Hooks/usePeriod';
import Loader from '../../../../Shared/Components/Loader/Loader';
import FeedbackButtonUrl from './FeedbackButton/FeedbackButtonUrl';
import UserInfo from '../../../../Models/User/UserInfo';
import FilterData from '../../../../Models/Dashboard/FilterData';

import './DashboardOverview.scss';

type PropType = {
  userInfo: UserInfo;
  showStartSurveyButton: boolean;
};

const DashboardOverview = ({ userInfo, showStartSurveyButton }: PropType) => {
  const surveyId = window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID;
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;

  const { t } = useTranslation();
  const { filterData } = useFilledInFilterData();
  const [showDetailedOverview, setShowDetailedOverview] = useState<boolean>(true);

  const { isLoading: periodLoading, data: periodData } = usePeriod(surveyId, {});

  const { isLoading, data: statistics } = useStatisticsEmployee(filterData.filterDataQuery);

  useEffect(() => {}, [filterData]);

  function handleToggleDomainStats() {
    setShowDetailedOverview(!showDetailedOverview);
  }

  const getLegendName = (filterDataItem: FilterData) => {
    const { self, team, unit, businessUnit, division, organisation } = filterDataItem;

    if (self && self.value !== null) {
      return self.label;
    }

    if (team.value !== null) {
      return team.label;
    }

    if (unit.value !== null) {
      return unit.label;
    }

    if (businessUnit.value !== null) {
      return businessUnit.label;
    }

    if (division.value !== null) {
      return division.label;
    }

    if (organisation.value !== null) {
      return organisation.label;
    }

    return t('dashboard.filters.all');
  };

  const legendName = getLegendName(filterData.scopeFilter[1]);

  if (periodLoading) {
    return <Loader />;
  }

  return (
    <>
      <FilterBar
        periodData={periodData!}
        introTitle={t('dashboard.employee.subtitle')}
        introParagraph={t('dashboard.employee.description')}
        isLoading={isLoading}
      />
      <div className="content-container">
        <div className="overview__statistics">
          <RadarChart
            statistics={statistics}
            legendLabelOne={t('dashboard.filters.your-score')}
            legendLabelTwo={legendName}
            isLoading={isLoading}
            isHR={false}
            showStartSurveyButton={showStartSurveyButton}
          />
          {statistics !== null && statistics !== undefined && statistics[0]?.domains.length > 0 && (
            <StatisticsRight
              dataPresent={statistics[0]?.domains.length > 0}
              lowest={statistics ? statistics![0].lowest : { key: '', value: 0 }}
              lowestTitle={statistics ? getLabelName(statistics, statistics![0].lowest.key, 0) : ''}
              highest={statistics ? statistics![0].highest : { key: '', value: 0 }}
              highestTitle={statistics ? getLabelName(statistics, statistics![0].highest.key, 0) : ''}
              isLoading={isLoading}
              showScores={client !== 'tno'}
            />
          )}
        </div>

        <ChartExplanation
          title={t('dashboard.domains.title')}
          text={client === 'tno' ? t('dashboard.domains.employee.description') : t('dashboard.domains.description')}
          domainScoreExplanation={t('dashboard.domains.DomainScoreExplanation')}
          legendLabelOne={t('dashboard.filters.your-score')}
          legendLabelTwo={legendName}
          onHandleToggleDomainStats={() => handleToggleDomainStats()}
          statsActive={showDetailedOverview}
        />
        <DomainOverview
          showStats={showDetailedOverview}
          statistics={statistics!}
          isLoading={isLoading}
        />
        {client !== 'TNO' && userInfo?.customerId && <FeedbackButtonUrl userInfo={userInfo} />}
      </div>
    </>
  );
};

export default DashboardOverview;
