import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getTeam } from '../Data/TeamService';

const useTeam = (teamId: string): UseQueryResult<FilterOption[], Error> =>
  useQuery(['Team', teamId], () => getTeam(teamId), {
    staleTime: undefined,
  });

export default useTeam;
