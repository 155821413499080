import classNames from 'classnames';

import './Loader.scss';

export type PropType = {
  white?: boolean;
};

const Loader = ({ white = false }: PropType) => (
  <div className={classNames('lds-ellipsis', { 'white-animation': white })}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loader;
