import { useTranslation } from 'react-i18next';
import FilterBarSelectButton from '../FilterBar/FilterBarSelectButtons/FilterBarSelectButton';

import './ChartExplanation.scss';

type PropType = {
  title: string;
  text: string;
  domainScoreExplanation: string;
  legendLabelOne: string;
  legendLabelTwo: string;
  onHandleToggleDomainStats: () => void;
  statsActive: boolean;
};

const ChartExplanation = ({
  title,
  text,
  domainScoreExplanation,
  legendLabelOne,
  legendLabelTwo,
  onHandleToggleDomainStats,
  statsActive,
}: PropType) => {
  const { t } = useTranslation();

  return (
    <div className="chart-explanation">
      <div className="content-left">
        <h3>{title}</h3>
        <p>{text}</p>
        <p>{domainScoreExplanation}</p>
        <div className="chart-explanation__legend">
          <div className="legend__info-row">
            <p>{legendLabelOne}</p>
          </div>
          {legendLabelTwo !== '' && (
            <div className="legend__info-row">
              <p className="green">{legendLabelTwo}</p>
            </div>
          )}
        </div>
      </div>
      <div className="content-right">
        <div className="toggle-button">
          <FilterBarSelectButton
            active={false}
            label={statsActive ? t('dashboard.filters.hide-stats') : t('dashboard.filters.view-stats')}
            onClick={onHandleToggleDomainStats}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartExplanation;
