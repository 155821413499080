import SurveyText from '../Survey/SurveyText';
import IScore from '../Interfaces/IDomainScore';
import Score from './Score';
import SubdomainLabel from './SubdomainLabel';

class Domain {
  domainDescription: SurveyText[];

  domainLabel: SurveyText[];

  domainScore: IScore;

  subdomainScores: IScore[];

  subdomainLabels: SubdomainLabel[];

  constructor(
    domainDescription: SurveyText[],
    domainLabel: SurveyText[],
    domainScore: IScore,
    subdomainScores: IScore[],
    subdomains: SubdomainLabel[],
  ) {
    this.domainDescription = domainDescription;
    this.domainLabel = domainLabel;
    this.domainScore = domainScore;
    this.subdomainScores = subdomainScores;
    this.subdomainLabels = subdomains;
  }

  public static parse(object: any) {
    return new Domain(
      object.DomainDescription.map((text: {}) => SurveyText.parse(text)),
      object.DomainLabel.map((text: {}) => SurveyText.parse(text)),
      Score.parse(object.DomainCalculationScore),
      object.SubdomainScores.map((subdomainScore: {}) => Score.parse(subdomainScore)),
      object.Subdomains.map((subdomainLabel: {}) => SubdomainLabel.parse(subdomainLabel)),
    );
  }
}

export default Domain;
