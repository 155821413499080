import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ISurveyHandler from '../../../Models/Interfaces/ISurveyHandler';
import { useFilledInSurveyData } from '../../../Store/SurveyContext';
import SurveyPostAnswer from '../../../Models/Survey/SurveyPostAnswer';
import SurveyPost from '../../../Models/Survey/SurveyPost';
import useAvailability from '../Hooks/useAvailability';
import Loader from '../../../Shared/Components/Loader/Loader';
import SurveyRender from './SurveyRender';
import { post } from '../Data/SurveyService';

type PropType = {
  forceWellbeing?: boolean;
  forceBackground?: boolean;
};

const SurveyPage = ({ forceWellbeing = false, forceBackground = false }: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;

  const navigate = useNavigate();

  const [currentSurvey, setCurrentSurvey] = useState<number>(0);
  const [questionNumber, setQuestionNumber] = useState<number>(0);
  const [showIntro, setShowIntro] = useState<boolean>(true);

  const { questionAnswers, setQuestionAnswers, surveyInformation } = useFilledInSurveyData();
  const { isLoading, data: surveyAvailability } = useAvailability();

  const surveys: ISurveyHandler[] = [];

  const buildSurveySubmit = () => {
    const parsedAnswers = questionAnswers.map(SurveyPostAnswer.parse);

    const surveyPost: SurveyPost = {
      UserResponseLanguage: 'en',
      Answers: parsedAnswers,
    };

    return surveyPost;
  };

  const showMenu = forceWellbeing || forceBackground;

  if (!isLoading) {
    if (surveyAvailability) {
      surveyAvailability.forEach((survey) => {
        switch (survey.surveyId) {
          case window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID:
            if ((survey.lastSubmissionDate === null || forceWellbeing) && survey.isAvailable) {
              surveys.push({ surveyId: window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID });
            }
            break;
          case window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_PROFILE_ID:
            if ((survey.lastSubmissionDate === null || forceBackground) && survey.isAvailable && client === 'tno') {
              surveys.push({ surveyId: window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_PROFILE_ID });
            }
            break;
          default:
            break;
        }
      });

      if (surveys!.length === 0) {
        navigate('/dashboard');
      }
    }
  }

  const handleNext = async () => {
    setShowIntro(false);

    if (questionNumber < surveyInformation.totalQuestions || surveyInformation.totalQuestions === 0) {
      setQuestionNumber(questionNumber + 1);
    } else if (questionNumber === surveyInformation.totalQuestions) {
      await post(surveyInformation.surveyId, buildSurveySubmit());
      setQuestionAnswers(undefined);

      if (currentSurvey < surveys.length - 1) {
        setCurrentSurvey(currentSurvey + 1);
        setQuestionNumber(1);
      } else {
        navigate('/dashboard');
      }
    }
  };

  const handlePrevious = () => {
    if (questionNumber >= 1) {
      setQuestionNumber(questionNumber - 1);
      if (questionNumber - 1 === 0) {
        setShowIntro(true);
      }
    }
  };

  const showLanguageSelectDuringSurvey = !(questionNumber !== 0 && client === 'tno');

  return (
    <>
      {!isLoading && surveys.length > 0 && (
        <SurveyRender
          surveyId={surveys[currentSurvey].surveyId}
          questionNumber={questionNumber}
          showMenu={showMenu}
          showLanguageSelectDuringSurvey={showLanguageSelectDuringSurvey}
          handleNext={() => handleNext()}
          handlePrevious={() => handlePrevious()}
          showIntro={showIntro}
        />
      )}

      {isLoading && <Loader />}
    </>
  );
};

export default SurveyPage;
