import SurveyQuestionType from './SurveyQuestionType';
import SurveyText from './SurveyText';
import SurveyQuestion from './SurveyQuestion';

class SurveySliderQuestion extends SurveyQuestion {
  key: string;

  isRequired: boolean;

  labelText: SurveyText[];

  hintText: SurveyText[];

  type: SurveyQuestionType;

  minLabelText: SurveyText[];

  maxLabelText: SurveyText[];

  minValue: number;

  maxValue: number;

  decimals: number;

  smallStepValue: number;

  largeStepValue: number;

  constructor(
    key: string,
    isRequired: boolean,
    labelText: SurveyText[],
    hintText: SurveyText[],
    surveyQuestionType: SurveyQuestionType,
    minLabelText: SurveyText[],
    maxLabelText: SurveyText[],
    minValue: number,
    maxValue: number,
    decimals: number,
    smallStepValue: number,
    largeStepValue: number,
    domain: string,
    subdomain: string,
    weight: number
  ) {
    super(key, isRequired, labelText, hintText, surveyQuestionType, domain, subdomain, weight);

    this.key = key;
    this.isRequired = isRequired;
    this.labelText = labelText;
    this.hintText = hintText;
    this.type = surveyQuestionType;
    this.minLabelText = minLabelText;
    this.maxLabelText = maxLabelText;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.decimals = decimals;
    this.smallStepValue = smallStepValue;
    this.largeStepValue = largeStepValue;
  }

  public static parse(object: any) {
    return new SurveySliderQuestion(
      object.Key,
      object.IsRequired,
      object.LabelText.map((text: {}) => SurveyText.parse(text)),
      object.HintText.map((text: {}) => SurveyText.parse(text)),
      object.QuestionType,
      object.MinLabelText.map((text: {}) => SurveyText.parse(text)),
      object.MaxLabelText.map((text: {}) => SurveyText.parse(text)),
      object.MinValue,
      object.MaxValue,
      object.Decimals,
      object.SmallStepValue,
      object.LargeStepValue,
      object.QuestionDomain,
      object.QuestionSubdomain,
      object.WeightDomain,
    );
  }
}

export default SurveySliderQuestion;
