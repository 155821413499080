import { useEffect, useState } from 'react';
import useBem from '@frontend/use-bem';
import classNames from 'classnames';

import Button from '../../../Button/Button';
import ButtonTheme from '../../../../Enums/ButtonTheme';

import './Modal.scss';

type PropType = {
  showModal?: boolean;
  removeTrigger?: boolean;
  title: string;
  description: string;
  confirmationButton?: string;
  cancelButton: string;
  onConfirmDeletion?: () => void;
  onCancelDeletion: () => void;
  isCopy?: boolean;
};

const ConfirmModal = ({
  showModal = false,
  removeTrigger = false,
  title,
  description,
  confirmationButton = 'Confirm',
  cancelButton,
  onConfirmDeletion = undefined,
  onCancelDeletion,
  isCopy = false,
}: PropType) => {
  const { bemClassName, bemClassNames } = useBem('modal');
  const [modalActive, setModalActive] = useState<boolean>(showModal);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    setIsCopied(false);
  }, [description]);

  function handleConfirmClick() {
    if (removeTrigger) {
      setModalActive(false);
      if (onConfirmDeletion) {
        onConfirmDeletion();
      }
    }
  }

  function handleCancelClick() {
    setModalActive(false);
    onCancelDeletion();
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(description).then(() => {
      setIsCopied(true);
    });
  };

  useEffect(() => {
    setModalActive(showModal);
  }, [showModal]);

  return (
    <div>
      {modalActive && (
        <div className={bemClassName('background')}>
          <div className={bemClassNames([bemClassName('content'), bemClassName('content', 'wide', isCopy)])}>
            <h2>{title}</h2>
            {!isCopy && <p>{description}</p>}

            {isCopy && (
              <div className={bemClassName('copy')}>
                <span className={bemClassName('copy__value')}>{description}</span>
                <button
                  type="button"
                  className={bemClassName('copy__button')}
                  onClick={() => copyToClipboard()}
                >
                  <span className={classNames('k-icon', { 'k-i-clipboard': !isCopied, 'k-i-checkmark': isCopied })} />
                </button>
              </div>
            )}

            <div className={bemClassName('buttons')}>
              <Button
                onClick={() => handleCancelClick()}
                label={cancelButton}
              />
              {removeTrigger && (
                <Button
                  onClick={() => handleConfirmClick()}
                  label={confirmationButton}
                  theme={ButtonTheme.DANGER}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmModal;
