import IScore from '../Interfaces/IDomainScore';

class Score implements IScore {
  key: string;

  value: number;

  constructor(key: string, value: number) {
    this.key = key;
    this.value = value;
  }

  public static parse(object: any) {
    return new Score(object.Key, object.Value);
  }
}

export default Score;
