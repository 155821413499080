import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getPeriods from '../Data/PeriodService';
import PeriodOptions from '../../../Models/Dashboard/PeriodOptions';
import FilterLevel from '../../../Shared/Enums/FilterLevel';

const usePeriod = (
  id: string,
  data:
    | {
        Level: FilterLevel;
        Id: string;
      }
    | {},
): UseQueryResult<PeriodOptions, Error> =>
  useQuery(['periods', id], () => getPeriods(id, data), {
    staleTime: undefined,
  });

export default usePeriod;
