class Customer {
  feedbackUrl: string;

  id: string;

  name: string;

  constructor(feedbackUrl: string, id: string, name: string) {
    this.feedbackUrl = feedbackUrl;
    this.id = id;
    this.name = name;
  }

  public static parse(object: any) {
    return new Customer(object.FeedbackUrl, object.Id, object.Name);
  }
}

export default Customer;
