import useBem from '@frontend/use-bem';

import CheckboxTypes from './enums';

import './Checkbox.scss';

type PropType = {
  checkboxId: string;
  parentName?: string;
  label: string;
  theme?: CheckboxTypes;
  boxChecked: boolean;
  showLabel?: boolean;
  imagePath?: string;
  onToggleChecked: (id: string, checked: boolean) => void;
};

const Checkbox = ({
  checkboxId,
  label,
  boxChecked,
  onToggleChecked,
  theme = CheckboxTypes.Default,
  parentName = undefined,
  showLabel = false,
  imagePath = undefined,
}: PropType) => {
  const { bemClassName, bemClassNames } = useBem('checkbox-wrapper');

  const toggleChecked = () => {
    onToggleChecked(checkboxId, !boxChecked);
  };

  return (
    <div className={bemClassName()}>
      <input
        className={bemClassNames([bemClassName('checkbox'), bemClassName('checkbox', 'blue', theme === 'blue')])}
        id={parentName === undefined ? checkboxId : `${parentName}-${checkboxId}`}
        type="checkbox"
        checked={boxChecked}
        title={label}
        onChange={() => toggleChecked()}
      />
      {showLabel && (
        <label
          className={bemClassName('label')}
          htmlFor={parentName === undefined ? checkboxId : `${parentName}-${checkboxId}`}
        >
          {imagePath && (
            <img
              src={imagePath}
              alt={label}
            />
          )}
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
