enum FilterLevel {
  CUSTOMER = 'Customer',
  ORGANISATION = 'Organisation',
  DIVISION = 'Division',
  BUSINESS_UNIT = 'BusinessUnit',
  UNIT = 'Unit',
  TEAM = 'Team',
  SELF = 'User',
}

export default FilterLevel;
