import useBem from '@frontend/use-bem';
import { useTranslation } from 'react-i18next';

import { DataGridSelectorType, SelectedGridEnum } from './types';

import './DataGridSelector.scss';

const DataGridSelector = ({
  selectedGrid,
  onGridSelect,
  gridLayer,
  disableLinkedUsers,
  disableSubLayers,
}: DataGridSelectorType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { bemClassName, bemClassNames } = useBem('data-grid-selector');
  const { t } = useTranslation();

  return (
    <div className={bemClassName('content')}>
      <button
        type="button"
        className={bemClassNames([
          bemClassName('item'),
          bemClassName('item', 'selected', selectedGrid === SelectedGridEnum.LayerDetails),
        ])}
        onClick={() => onGridSelect(SelectedGridEnum.LayerDetails)}
        disabled={disableSubLayers}
      >
        {t(`management.${gridLayer}.title`)}
      </button>
      {client !== 'tno' && (
        <button
          type="button"
          className={bemClassNames([
            bemClassName('item'),
            bemClassName('item', 'selected', selectedGrid === SelectedGridEnum.LinkedUsers),
          ])}
          onClick={() => onGridSelect(SelectedGridEnum.LinkedUsers)}
          disabled={disableLinkedUsers}
        >
          {t('grid-selector.linked-users')}
        </button>
      )}
    </div>
  );
};

export default DataGridSelector;
