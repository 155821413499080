import useBem from '@frontend/use-bem';
import { Trans, useTranslation } from 'react-i18next';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';

import './PublicSurveyPageCompleted.scss';

const SurveyPage = () => {
  const { bemClassName } = useBem('survey-completed-page');
  const { t } = useTranslation();

  return (
    <div className={bemClassName()}>
      <NavigationBar
        hasTitle
        menuTitle={t('dashboard.general.menu-title')}
        hasBorder={false}
        showProfile={false}
      />
      <div className="content-container">
        <div className="content-container__content">
          <h1>
            <Trans
              i18nKey="survey.public.completed.title"
              components={{
                b: <b />,
              }}
            />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default SurveyPage;
