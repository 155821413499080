import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from './Localization/en/common.json';
import commonNl from './Localization/nl/common.json';

import tnoEn from './Localization/en/tno.json';
import tnoNl from './Localization/nl/tno.json';

const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;

const resources = {
  en: { common: client === 'wellatwork' ? commonEn : tnoEn },
  nl: { common: client === 'wellatwork' ? commonNl : tnoNl },
};

const detection = {
  order: ['localStorage', 'navigator'],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection,
    supportedLngs: ['nl', 'en'],
    fallbackLng: 'en',
    defaultNS: 'common',
    ns: ['common'],
    interpolation: {
      escapeValue: false,
    },
    resources,
  });


document.getElementsByTagName('html')[0].setAttribute('lang', i18n.language);

export default i18n;
