import SurveyScore from '../../../Models/Dashboard/SurveyScore';
import ICompareStatisticsRequestData from '../../../Models/Interfaces/ICompareStatisticsRequestData';
import IStatisticsRequestData from '../../../Models/Interfaces/IStatisticsRequestData';
import http from '../../../Utils/HttpClient';

const base = `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_SURVEYS}/Surveys`;

const getStatisticsEmployer = async (statisticsId: string, statisticsRequestData: IStatisticsRequestData) => {
  const transformedRequestData = {
    ...statisticsRequestData,
    DateFilters: statisticsRequestData.DateFilters?.map((dateFilter) => ({
      From: dateFilter?.from,
      To: dateFilter?.to,
    })),
  };

  const { data } = (await http.post(`${base}/${statisticsId}/CalculateSurveyScores`, transformedRequestData)) as any;

  return data ? [SurveyScore.parse(data)] : null;
};

const getCompareStatisticsEmployer = async (
  statisticsId: string,
  statisticsRequestData: ICompareStatisticsRequestData,
) => {
  const transformedRequestData = {
    Filter1: statisticsRequestData.Filter1.DateFilters && {
      ...statisticsRequestData.Filter1,
      DateFilters: statisticsRequestData.Filter1.DateFilters?.map((dateFilter) => ({
        From: dateFilter?.from,
        To: dateFilter?.to,
      })),
    },
    Filter2: statisticsRequestData.Filter2.DateFilters && {
      ...statisticsRequestData.Filter2,
      DateFilters: statisticsRequestData.Filter2.DateFilters?.map((dateFilter) => ({
        From: dateFilter?.from,
        To: dateFilter?.to,
      })),
    },
  };

  const { data } = (await http.post(`${base}/${statisticsId}/CompareSurveyScores`, transformedRequestData)) as any;

  return data.value ? data.value.map(SurveyScore.parse) : null;
};

export { getStatisticsEmployer, getCompareStatisticsEmployer };
