import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from '@progress/kendo-react-inputs';

import QuestionTitle from '../../../../Shared/Components/QuestionTitle/QuestionTitle';
import { SurveyQuestionType } from '../SurveyQuestionTypes';

import '../SurveyQuestion.scss';

const OpenQuestion = ({ question, questionKey, onAnswerSelect, previousAnswer, surveyId }: SurveyQuestionType) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>(previousAnswer);
  const { t } = useTranslation();

  const handleOnChange = (value: string) => {
    setSelectedAnswer(value);

    onAnswerSelect({
      textValue: value,
      questionKey,
      questionLabel: question,
      surveyId,
    });
  };

  return (
    <div className="content-container">
      <div className="survey">
        <div className="survey-question">
          <div className="survey-question__title">
            <QuestionTitle title={question} />
          </div>
          <div className="survey-question__answers">
            <div className="survey-question__input">
              <TextArea
                value={selectedAnswer}
                placeholder={t('survey.placeholder')!}
                onChange={(e) => {
                  const { value } = e;
                  handleOnChange(value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenQuestion;
