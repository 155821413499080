import { useQuery, UseQueryResult } from '@tanstack/react-query';
import Survey from '../../../Models/Survey/Survey';
import { get, getPublic } from '../Data/SurveyService';

const useSurvey = (surveyId: string, isPublic: boolean): UseQueryResult<Survey, Error> =>
  useQuery(['survey', surveyId], () => (isPublic ? getPublic(surveyId) : get(surveyId)), {
    staleTime: undefined,
  });

export default useSurvey;
