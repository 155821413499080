import './QuestionTitle.scss';

type PropType = {
  title: string;
};

const QuestionTitle = ({ title }: PropType) => (
  <h1
    className="question-title"
    /* eslint-disable-next-line react/no-danger */
    dangerouslySetInnerHTML={{ __html: title }}
  />
);

export default QuestionTitle;
