import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../../../Shared/Components/Loader/Loader';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import Level from '../../../Shared/Enums/Level';
import useUser from '../../../Shared/Hooks/useUser';
import useDivision from '../../Dashboard/Hooks/useDivision';
import { SelectedGridEnum } from '../../../Shared/Components/DataGridSelector/types';
import ManagementPageHeader from './components/ManagementPageHeader/ManagementPageHeader';
import DataGridSelector from '../../../Shared/Components/DataGridSelector/DataGridSelector';
import UserDataGrid from '../../../Shared/Components/DataGrid/UserDataGrid';
import LayerDataGrid from '../../../Shared/Components/DataGrid/LayerDataGrid';

import '../management.scss';

const OrganisationsManagementPage = () => {
  const [selectedGrid, setSelectedGrid] = useState<SelectedGridEnum>(SelectedGridEnum.LayerDetails);
  const { t } = useTranslation();
  const { userInfo } = useUser();
  const navigate = useNavigate();
  const { divisionId } = useParams();

  const { isLoading: divisionLoading, data: division } = useDivision(divisionId!);

  if (divisionLoading) {
    return (
      <div className="loader-position">
        <Loader />
      </div>
    );
  }

  // CHECK-RIGHT HR
  if (userInfo?.checkPermission('write:divisions:organisation')) {
    return (
      <>
        <NavigationBar
          hasTitle
          hasBorder={false}
          menuTitle={t('dashboard.general.menu-title')}
          showProfile
        />
        <div className="content-container">
          <ManagementPageHeader
            title={division ? division[0].name : t('management.Divisions.title')}
            gridLayer={Level.DIVISION}
          />
          <DataGridSelector
            selectedGrid={selectedGrid}
            onGridSelect={(grid) => setSelectedGrid(grid)}
            gridLayer={Level.DIVISION}
          />
          {selectedGrid === SelectedGridEnum.LayerDetails ? (
            <LayerDataGrid
              gridLayer={Level.DIVISION}
              removePermission="write:divisions:organisation"
            />
          ) : (
            <UserDataGrid level={Level.DIVISION} />
          )}
        </div>
      </>
    );
  }

  if (userInfo) {
    navigate('/dashboard');
  }

  return (
    <div className="loader-position">
      <Loader />
    </div>
  );
};

export default OrganisationsManagementPage;
