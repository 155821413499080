import FilterOption from '../../../Models/Dashboard/FilterOption';
import http from '../../../Utils/HttpClient';

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const getOrganisation = async (organisationId: string) => {
  const { data } = (await http.get(`${base}/Organisations/${organisationId}`)) as any;

  return [FilterOption.parse(data)];
};

const getAllOrganisations = async () => {
  const { data } = (await http.get(`${base}/Organisations`)) as any;

  return data.value.map(FilterOption.parse);
};

export { getOrganisation, getAllOrganisations };
