enum SurveyQuestionType {
  SINGLE_CHOICE = 'SingleChoice',
  MULTIPLE_CHOICE = 'MultipleChoice',
  LIKERT = 'Likert',
  SLIDER = 'Slider',
  NUMBER = 'Number',
  OPEN = 'Open'
}

export default SurveyQuestionType;
