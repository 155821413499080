import classNames from 'classnames';

import IconRender from '../Icon/IconRender';
import IconPosition from '../../Enums/IconPosition';
import Icon from '../../Enums/Icon';

import ButtonTheme from '../../Enums/ButtonTheme';

import './Button.scss';

type PropType = {
  disabled?: boolean;
  icon: Icon;
  iconPosition?: IconPosition;
  isSubmit?: boolean;
  label?: string;
  onClick: () => void;
  theme?: ButtonTheme;
};

const IconButton = ({
  icon,
  onClick,
  disabled = false,
  iconPosition = IconPosition.right,
  isSubmit = false,
  label = '',
  theme = ButtonTheme.NORMAL,
}: PropType) => (
  <button
    type={isSubmit ? 'submit' : 'button'}
    className={classNames(
      ['button', `button--${theme}`],
      { 'button--disabled': disabled },
      { [`button--icon-${iconPosition}`]: iconPosition },
    )}
    title={label || undefined}
    onClick={(e: any): void => {
      e.preventDefault();
      onClick();
    }}
    disabled={disabled}
  >
    <span
      className="button__label"
      data-label={label}
    >
      {label}
    </span>
    <IconRender
      className="button__icon"
      icon={icon}
      width="15px"
      height="15px"
    />
  </button>
);

export default IconButton;
