import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import '../SurveyQuestion.scss';

type PropType = {
  question: string;
  questionText: string;
};

const StartSurvey = ({ question, questionText }: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { i18n } = useTranslation();

  let image = i18n.language === 'nl' ? 'surveyStartDutch' : 'surveyStart';
  if (client === 'tno') {
    image = i18n.language === 'nl' ? 'surveyStartTNODutch' : 'surveyStartTNO';
  }

  return (
    <div className={classNames('content-container survey--start', { tno: client === 'tno' })}>
      <div className="dashboard-header">
        <h1
          dangerouslySetInnerHTML={{ __html: question /* eslint-disable-line react/no-danger */ }}
          className="dashboard-header__title dashboard-header__title--medium"
        />
      </div>
      <div className="overview__intro no-border">
        <div className="paragraph paragraph__with-image">
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: questionText }} />

          <img
            src={`/images/general/${image}.png`}
            alt="Survey start"
          />
        </div>
      </div>
    </div>
  );
};

export default StartSurvey;
