import SurveyText from '../Models/Survey/SurveyText';
import i18n from '../i18n';

class LanguageHelper {
  static SelectLanguage(texts: SurveyText[]): string {
    // eslint-disable-next-line array-callback-return
    return texts.find((t: SurveyText) => {
      if (t.language) return t.language.startsWith(i18n.language);
      return t.culture.startsWith(i18n.language);
    })!.value;
  }
}

export default LanguageHelper;
