import classNames from 'classnames';

import './QuestionButton.scss';

type PropType = {
  active: boolean;
  label?: string;
  onClick: () => void;
};

const QuestionButton = ({ active, onClick, label = '' }: PropType) => (
  <button
    type="button"
    className={classNames('question-button', { 'question-button--active': active })}
    title={label || undefined}
    onClick={(e: any): void => {
      e.preventDefault();
      onClick();
    }}
  >
    {label}
  </button>
);

export default QuestionButton;
