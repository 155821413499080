class FilterOption {
  id: string | null;

  parentId?: string;

  name: string;

  teamCode?: string;

  constructor(id: string, parentId: string | undefined, name: string, teamCode: string | undefined) {
    this.id = id;
    this.parentId = parentId;
    this.name = name;
    this.teamCode = teamCode;
  }

  public static parse(object: any) {
    if (object.CustomerId !== undefined) {
      return new FilterOption(object.Id, object.CustomerId, object.Name, undefined);
    }
    if (object.OrganisationId !== undefined) {
      return new FilterOption(object.Id, object.OrganisationId, object.Name, undefined);
    }
    if (object.DivisionId !== undefined) {
      return new FilterOption(object.Id, object.DivisionId, object.Name, object.Code);
    }
    if (object.BusinessUnitId !== undefined) {
      return new FilterOption(object.Id, object.BusinessUnitId, object.Name, object.Code);
    }
    if (object.UnitId !== undefined) {
      return new FilterOption(object.Id, object.UnitId, object.Name, object.Code);
    }
    return new FilterOption(object.Id, undefined, object.Name, undefined);
  }
}

export default FilterOption;
