import i18n from 'i18next';
import http from '../../../Utils/HttpClient';

type Item = {
  Name?: string;
  CustomerId?: string;
  OrganisationId?: string;
  UnitId?: string;
};

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const addLayerItem = async (level: string, item: Item) => {
  const { data } = (await http.post(`${base}/${level}`, item).then(() => {
    document.location.reload();
  })) as any;

  return data;
};

const editLayerItem = async (level: string, itemId: string, item: Item) => {
  const { data } = (await http.patch(`${base}/${level}/${itemId}`, item)) as any;

  return data;
};

const deleteLayerItem = async (level: string, itemId: string) => {
  const { data } = (await http
    .delete(`${base}/${level}/${itemId}`)
    .then(() => {
      document.location.reload();
    })
    .catch((error) => {
      if (error.status === 500) {
        if (i18n.language === 'nl') {
          // eslint-disable-next-line no-alert
          alert(
            'Er zijn nog gebruikers gekoppeld aan het item dat je wilt verwijderen. Verplaats eerst de gebruikers in alle gekoppelde teams.',
          );
        } else {
          // eslint-disable-next-line no-alert
          alert(
            'There are still users linked to the item you want to delete. First remove the users in all linked teams.',
          );
        }
      }
    })) as any;

  return data;
};

export { addLayerItem, editLayerItem, deleteLayerItem };
