import { useParams } from 'react-router-dom';
import { GridCellProps } from '@progress/kendo-react-grid';
import useBem from '@frontend/use-bem';

import Level from '../../../../Enums/Level';

const NameCell = ({ gridCellProps, level }: { gridCellProps: GridCellProps; level: Level | undefined }) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { bemClassName, bemClassNames } = useBem('default-cell');
  const { customerId, organisationId, divisionId, businessUnitId, unitId } = useParams();
  const { dataItem } = gridCellProps;
  const { Name, Id } = dataItem;

  let url = '';

  switch (level) {
    case Level.ADMIN:
      url = `/backoffice/${Id}`;
      break;
    case Level.CUSTOMER:
      url = `/backoffice/${customerId}/${Id}`;
      break;
    case Level.ORGANISATION:
      url = `/backoffice/${customerId}/${organisationId}/${Id}`;
      break;
    case Level.DIVISION:
      url = `/backoffice/${customerId}/${organisationId}/${divisionId}/${Id}`;
      break;
    case Level.BUSINESS_UNIT:
      url = `/backoffice/${customerId}/${organisationId}/${divisionId}/${businessUnitId}/${Id}`;
      break;
    case Level.UNIT:
      url = `/backoffice/${customerId}/${organisationId}/${divisionId}/${businessUnitId}/${unitId}/${Id}`;
      break;
    default:
      throw new Error('Invalid level');
  }

  return (
    <td
      className={bemClassNames(['k-table-td', bemClassName()])}
      colSpan={1}
    >
      {client !== 'tno' || (client === 'tno' && level === Level.ADMIN) ? (
        <a
          href={url}
          title={Name}
        >
          <b>{Name}</b>
        </a>
      ) : (
        <span>
          <b>{Name}</b>
        </span>
      )}
    </td>
  );
};

export default NameCell;
