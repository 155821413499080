import PeriodFilterOption from './PeriodFilterOption';

class PeriodOptions {
  global: PeriodFilterOption[];

  personal: PeriodFilterOption[];

  constructor(globalFilters: PeriodFilterOption[], personalFilters: PeriodFilterOption[]) {
    this.global = globalFilters;
    this.personal = personalFilters;
  }

  public static parse(object: any) {
    return new PeriodOptions(
      PeriodFilterOption.parseList(object.Months),
      PeriodFilterOption.parseList(object.OwnMonths, true),
    );
  }
}

export default PeriodOptions;
