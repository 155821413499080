import useBem from '@frontend/use-bem';

import Domain from './Model/Domain';
import DomainStatistic from './Model/DomainStatistic';
import DomainStat from './DomainStat';

import './DomainInfo.scss';

type PropType = Domain & {
  showStats: boolean;
  comparisonScore: number;
};

const DomainInfo = ({ name, stats, comparisonScore, showStats, value, description }: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const showScore = client !== 'tno';
  const { bemClassName, bemClassNames } = useBem('domain-info');

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, 'tno', client === 'tno')])}>
      <div className={bemClassName('title')}>
        <span>{name}</span>
        {showScore && (
          <span className={bemClassName('title', 'scores')}>
            <b className={bemClassName('title', 'score')}>{value}</b>
            {comparisonScore > 0 && (
              <>
                {' - '}
                <b className={bemClassName('title', 'compare-score')}>{comparisonScore}</b>
              </>
            )}
          </span>
        )}
      </div>
      <div className={bemClassName('__separator')} />
      <div
        className={bemClassNames([
          bemClassName('description'),
          bemClassName('description', 'margin-bottom', showStats),
        ])}
      >
        {description}
      </div>
      <div className={bemClassNames([bemClassName('statistics'), bemClassName('statistics', 'show', showStats)])}>
        {stats.map((stat: DomainStatistic) => (
          <DomainStat
            key={stat.name}
            {...stat}
            showScore={showScore}
          />
        ))}
      </div>
    </div>
  );
};

export default DomainInfo;
