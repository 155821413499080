import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import FilterBarSelectButton from './FilterBarSelectButtons/FilterBarSelectButton';
import { useFilledInFilterData } from '../../../../../Store/DashboardContext';
import IFilterValue from '../../../../../Models/Interfaces/IFilterValue';
import DashboardContent from '../../../../../Models/Dashboard/DashboardContent';
import FilterOption from '../../../../../Models/Dashboard/FilterOption';
import FilterLevel from '../../../../../Shared/Enums/FilterLevel';
import useUser from '../../../../../Shared/Hooks/useUser';
import Loader from '../../../../../Shared/Components/Loader/Loader';
import PeriodFilter from '../PeriodFilter/PeriodFilter';
import PeriodOptions from '../../../../../Models/Dashboard/PeriodOptions';
import PeriodFilterOption from '../../../../../Models/Dashboard/PeriodFilterOption';
import getPeriods from '../../../Data/PeriodService';
import { EmployerFiltersType } from './types';

import 'react-datepicker/dist/react-datepicker.css';

type PropType = {
  introTitle: string;
  organisations: FilterOption[];
  divisions: FilterOption[];
  businessUnits: FilterOption[];
  units: FilterOption[];
  teams: FilterOption[];
  periodData: PeriodOptions;
  isLoading: boolean;
};

const FilterBarEmployer = ({
  introTitle,
  organisations,
  divisions,
  businessUnits,
  units,
  teams,
  periodData,
  isLoading,
}: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;

  const { t } = useTranslation();
  const { userInfo } = useUser();

  const { global } = periodData;

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [filterOneData, setFilterOneData] = useState<EmployerFiltersType>();
  const [filterTwoData, setFilterTwoData] = useState<EmployerFiltersType>();

  const { filterData, setFilterData, resetFilterData } = useFilledInFilterData();

  const [periodFilterOneOptions, setPeriodFilterOneOptions] = useState<PeriodFilterOption[]>(global);
  const [periodFilterTwoOptions, setPeriodFilterTwoOptions] = useState<PeriodFilterOption[]>(
    filterData.filterDataQuery.Filter2.Level !== null || client === 'tno' ? global : [],
  );

  const buildFilterOptionArray = (filter: number, data: FilterOption[], type: string) => {
    const filterOptions: IFilterValue[] = [{ value: null, label: t(`dashboard.filters.${type.toLowerCase()}`) }];

    data.forEach(({ name, id, parentId }) => {
      const scopefilter = filterData.scopeFilter[filter];

      switch (type) {
        case FilterLevel.TEAM:
          if (scopefilter.unit.value === parentId || scopefilter.unit.value === null) {
            filterOptions.push({ value: id, label: name });
          }
          break;
        case FilterLevel.UNIT:
          if (scopefilter.businessUnit.value === parentId || scopefilter.businessUnit.value === null) {
            filterOptions.push({ value: id, label: name });
          }
          break;
        case FilterLevel.BUSINESS_UNIT:
          if (scopefilter.division.value === parentId || scopefilter.division.value === null) {
            filterOptions.push({ value: id, label: name });
          }
          break;
        case FilterLevel.DIVISION:
          if (scopefilter.organisation.value === parentId || scopefilter.organisation.value === null) {
            filterOptions.push({ value: id, label: name });
          }
          break;
        default:
          filterOptions.push({ value: id, label: name });
          break;
      }
    });

    return filterOptions;
  };

  function changeFilterPeriods(selectedPeriods: PeriodFilterOption[], filter: number = 1) {
    const newFilterData: DashboardContent = filterData;

    if (filter === 1) {
      newFilterData.filterDataQuery.Filter1.DateFilters = selectedPeriods;
    } else if (filter === 2) {
      newFilterData.filterDataQuery.Filter2.DateFilters = selectedPeriods;
    }

    setFilterData(newFilterData);
  }

  function changeScopeData(filter: number, filterLevel: FilterLevel, data: IFilterValue) {
    const newFilterData: DashboardContent = filterData;
    const surveyId = window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID;
    const filterPath = newFilterData.scopeFilter[filter];
    const filterPathLevels = Object.keys(filterPath);
    const filterLevelIndex = filterPathLevels.findIndex(
      (key) => key.toString().toLowerCase() === filterLevel.toString().toLowerCase(),
    );

    Object.keys(filterPath).forEach((key, index) => {
      if (index > filterLevelIndex) {
        // @ts-ignore
        filterPath[key] = {
          value: null,
          label: t(`dashboard.filters.${key.toLowerCase()}`),
        };
      }
    });

    switch (filterLevel) {
      case FilterLevel.ORGANISATION:
        filterPath.organisation = {
          value: data.value,
          label: data.label,
        };
        break;
      case FilterLevel.DIVISION:
        filterPath.division = {
          value: data.value,
          label: data.label,
        };
        break;
      case FilterLevel.BUSINESS_UNIT:
        filterPath.businessUnit = {
          value: data.value,
          label: data.label,
        };
        break;
      case FilterLevel.UNIT:
        filterPath.unit = {
          value: data.value,
          label: data.label,
        };
        break;
      case FilterLevel.TEAM:
        filterPath.team = {
          value: data.value,
          label: data.label,
        };
        break;
      default:
        break;
    }

    switch (filter) {
      case 0:
        getPeriods(surveyId, data.value !== '' ? { Level: filterLevel, Id: data.value } : {}).then((periods) => {
          setPeriodFilterOneOptions(periods.global);
          newFilterData.filterDataQuery.Filter1.DateFilters = [];
        });
        break;
      case 1:
        getPeriods(surveyId, data.value !== '' ? { Level: filterLevel, Id: data.value } : {}).then((periods) => {
          setPeriodFilterTwoOptions(newFilterData.filterDataQuery.Filter2.Id !== null ? periods.global : []);

          if (client !== 'tno' && newFilterData.filterDataQuery.Filter2.Id !== null) {
            newFilterData.filterDataQuery.Filter2.DateFilters = [];
          }
        });
        break;
      default:
        break;
    }

    setFilterData(newFilterData);
  }

  useEffect(() => {
    if (global && global.length > 0 && !filterData.filterDataQuery.Filter1.DateFilters.length) {
      changeFilterPeriods([periodData.global[0]], 1);
    }
  }, []);

  useEffect(() => {
    setFilterOneData({
      organsations: buildFilterOptionArray(0, organisations, FilterLevel.ORGANISATION),
      divisions: buildFilterOptionArray(0, divisions, FilterLevel.DIVISION),
      businessUnits: buildFilterOptionArray(0, businessUnits, FilterLevel.BUSINESS_UNIT),
      units: buildFilterOptionArray(0, units, FilterLevel.UNIT),
      teams: buildFilterOptionArray(0, teams, FilterLevel.TEAM),
    });

    setFilterTwoData({
      organsations: buildFilterOptionArray(1, organisations, FilterLevel.ORGANISATION),
      divisions: buildFilterOptionArray(1, divisions, FilterLevel.DIVISION),
      businessUnits: buildFilterOptionArray(1, businessUnits, FilterLevel.BUSINESS_UNIT),
      units: buildFilterOptionArray(1, units, FilterLevel.UNIT),
      teams: buildFilterOptionArray(1, teams, FilterLevel.TEAM),
    });
  }, [filterData]);

  const setInitialDefault = () => {
    if (userInfo) {
      const { teamId, organisationId } = userInfo;

      const isHumanResources = userInfo.checkPermission('assign:role:humanresources');
      const isTeamLead = userInfo.checkPermission('assign:role:teamlead');

      let filterName: string | null = null;
      let selectedId = '';

      if (isTeamLead) {
        filterName = 'teams';
        selectedId = teamId!;
      }

      if (isHumanResources || client === 'tno') {
        filterName = 'organsations';
        selectedId = organisationId;
      }

      if (filterOneData && filterName) {
        // @ts-ignore
        const value = filterOneData[filterName].find((option) => option?.value === selectedId);

        if (value) {
          const filterLevel = isHumanResources ? FilterLevel.ORGANISATION : FilterLevel.TEAM;
          changeScopeData(0, filterLevel, value);
        }
      }

      setHasLoaded(true);
    }
  };

  useEffect(() => {
    setInitialDefault();
  }, [userInfo]);

  if (!hasLoaded) {
    return <Loader />;
  }

  return (
    <div className="content-container">
      <div className="overview__intro overview__intro--employer">
        <div className="paragraph">
          <h3>{introTitle}</h3>
        </div>

        {!isLoading && (
          <div className="filter-row">
            <div className="filter-column">
              <div className="column-row">
                <span>
                  <b>{t('dashboard.filters.view')}</b>
                </span>

                {client !== 'tno' && (
                  <div className="scope-selection">
                    <FilterBarSelectButton
                      active={
                        filterData.scopeFilter[0].organisation.value === null &&
                        filterData.scopeFilter[0].unit.value === null &&
                        filterData.scopeFilter[0].team.value === null
                      }
                      label={t('dashboard.filters.all')}
                      onClick={() => {
                        changeScopeData(0, FilterLevel.ORGANISATION, {
                          value: null,
                          label: t('dashboard.filters.organisation'),
                        });
                        changeScopeData(0, FilterLevel.DIVISION, {
                          value: null,
                          label: t('dashboard.filters.division'),
                        });
                        changeScopeData(0, FilterLevel.BUSINESS_UNIT, {
                          value: null,
                          label: t('dashboard.filters.businessunit'),
                        });
                        changeScopeData(0, FilterLevel.UNIT, {
                          value: null,
                          label: t('dashboard.filters.unit'),
                        });
                        changeScopeData(0, FilterLevel.TEAM, {
                          value: null,
                          label: t('dashboard.filters.team'),
                        });
                      }}
                    />
                    {filterOneData?.organsations && (
                      <Select
                        value={filterData.scopeFilter[0].organisation}
                        classNamePrefix="react-select"
                        className="organisation"
                        options={filterOneData.organsations}
                        onChange={(v): void => {
                          changeScopeData(0, FilterLevel.ORGANISATION, v!);
                        }}
                      />
                    )}
                    {filterOneData?.divisions && (
                      <Select
                        value={filterData.scopeFilter[0].division}
                        classNamePrefix="react-select"
                        className="division"
                        options={filterOneData.divisions}
                        onChange={(v): void => {
                          changeScopeData(0, FilterLevel.DIVISION, v!);
                        }}
                      />
                    )}
                    {filterOneData?.businessUnits && (
                      <Select
                        value={filterData.scopeFilter[0].businessUnit}
                        classNamePrefix="react-select"
                        className="businessUnit"
                        options={filterOneData.businessUnits}
                        onChange={(v): void => {
                          changeScopeData(0, FilterLevel.BUSINESS_UNIT, v!);
                        }}
                      />
                    )}
                    {filterOneData?.units && (
                      <Select
                        value={filterData.scopeFilter[0].unit}
                        classNamePrefix="react-select"
                        className="businessUnit"
                        options={filterOneData.units}
                        onChange={(v): void => {
                          changeScopeData(0, FilterLevel.UNIT, v!);
                        }}
                      />
                    )}

                    {filterOneData?.teams && (
                      <Select
                        value={filterData.scopeFilter[0].team}
                        classNamePrefix="react-select"
                        className="team"
                        options={filterOneData.teams}
                        onChange={(v): void => {
                          changeScopeData(0, FilterLevel.TEAM, v!);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="column-row column-row__right">
                <div className="date-selectors">
                  <span>{t('dashboard.filters.period')}</span>
                  <PeriodFilter
                    data={periodFilterOneOptions}
                    selectedValue={filterData.filterDataQuery.Filter1.DateFilters}
                    onChange={(selectedFilters: PeriodFilterOption[]) => {
                      changeFilterPeriods(selectedFilters, 1);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="filter-column filter-column__margin-left">
              <div className="column-row">
                <span>
                  <b>{t('dashboard.filters.compare-to')}</b>
                </span>
                {client !== 'tno' && (
                  <div className="scope-container">
                    {filterTwoData && (
                      <div className="scope-selection">
                        {filterTwoData?.organsations && (
                          <Select
                            value={filterData.scopeFilter[1].organisation}
                            classNamePrefix="react-select"
                            className="organisation"
                            options={filterTwoData.organsations}
                            onChange={(v): void => {
                              changeScopeData(1, FilterLevel.ORGANISATION, v!);
                            }}
                          />
                        )}
                        {filterTwoData?.divisions && (
                          <Select
                            value={filterData.scopeFilter[1].division}
                            classNamePrefix="react-select"
                            className="division"
                            options={filterTwoData.divisions}
                            onChange={(v): void => {
                              changeScopeData(1, FilterLevel.DIVISION, v!);
                            }}
                          />
                        )}
                        {filterTwoData?.businessUnits && (
                          <Select
                            value={filterData.scopeFilter[1].businessUnit}
                            classNamePrefix="react-select"
                            className="businessUnit"
                            options={filterTwoData.businessUnits}
                            onChange={(v): void => {
                              changeScopeData(1, FilterLevel.BUSINESS_UNIT, v!);
                            }}
                          />
                        )}
                        {filterTwoData?.units && (
                          <Select
                            value={filterData.scopeFilter[1].unit}
                            classNamePrefix="react-select"
                            className="unit"
                            options={filterTwoData.units}
                            onChange={(v): void => {
                              changeScopeData(1, FilterLevel.UNIT, v!);
                            }}
                          />
                        )}
                        {filterTwoData?.teams && (
                          <Select
                            value={filterData.scopeFilter[1].team}
                            classNamePrefix="react-select"
                            className="team"
                            options={filterTwoData.teams}
                            onChange={(v): void => {
                              changeScopeData(1, FilterLevel.TEAM, v!);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="column-row column-row__right">
                <div className="date-selectors">
                  <span>{t('dashboard.filters.period')}</span>
                  <PeriodFilter
                    data={periodFilterTwoOptions}
                    selectedValue={filterData.filterDataQuery.Filter2.DateFilters}
                    onChange={(selectedFilters: PeriodFilterOption[]) => {
                      changeFilterPeriods(selectedFilters, 2);

                      if (client === 'tno' && userInfo) {
                        changeScopeData(1, FilterLevel.ORGANISATION, {
                          value: userInfo.organisationId,
                          label: t('dashboard.filters.organisation'),
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="column-row column-row__right">
                <button
                  className="button--stripped"
                  onClick={() => {
                    resetFilterData(client === 'tno');
                  }}
                  type="button"
                >
                  {t('dashboard.filters.reset-filters')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBarEmployer;
