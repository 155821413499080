import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import StatBlockTheme from '../../Enums/StatBlockTheme';

import './StatBlockEmployer.scss';

type PropType = {
  highestScore: number;
  highestCategory: string;
  lowestScore: number;
  lowestCategory: string;
  measurement: string;
  theme: StatBlockTheme;
  isLoading: boolean;
};

const StatBlockBig = ({
  highestScore,
  highestCategory,
  lowestScore,
  lowestCategory,
  measurement,
  theme,
  isLoading,
}: PropType) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={classNames('stat-block-employer', `stat-block-employer--${theme}`)}>
        {!isLoading && highestScore !== 0 && (
          <div className="stat-block-employer__row">
            <div className="score">
              <span className="score--large">{highestScore}</span>
              <span className="score--small">{measurement}</span>
            </div>
            <p>
              {t(`dashboard.manager.cards.highest-in-category`)}
              <b>{highestCategory}</b>
            </p>
          </div>
        )}
      </div>
      <div className={classNames('stat-block-employer', `stat-block-employer--${theme}`)}>
        {!isLoading && highestScore !== 0 && (
          <div className="stat-block-employer__row">
            <div className="score">
              <span className="score--large">{lowestScore}</span>
              <span className="score--small">{measurement}</span>
            </div>
            <p>
              {t(`dashboard.manager.cards.lowest-in-category`)}
              <b>{lowestCategory}</b>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default StatBlockBig;
