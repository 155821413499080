import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { SurveyShortInfo } from '../Types/types';
import { getAllInfo } from '../../Features/Survey/Data/SurveyService';

const useAvailableSurveys = (): UseQueryResult<SurveyShortInfo[], Error> =>
  useQuery(['surveys'], () => getAllInfo(), {
    staleTime: undefined,
  });

export default useAvailableSurveys;
