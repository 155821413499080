import { useQuery, UseQueryResult } from '@tanstack/react-query';
import SurveyScore from '../../../Models/Dashboard/SurveyScore';
import { getCompareStatisticsEmployee, getStatisticsEmployee } from '../Data/StatisticsEmployeeService';

const useStatisticsEmployer = (filter: any): UseQueryResult<SurveyScore[], Error> => {
  const surveyId = window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID;

  return useQuery(
    ['statistics', filter],
    () =>
      filter.Filter2.Id === null
        ? getStatisticsEmployee(surveyId)
        : getCompareStatisticsEmployee(surveyId, filter.Filter2),

    {
      staleTime: undefined,
    },
  );
};

export default useStatisticsEmployer;
