import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormElement } from '@progress/kendo-react-form';

import { editUser } from '../../Management/Data/UserManagerService';
import useUser from '../../../Shared/Hooks/useUser';
import Button from '../../../Shared/Components/Button/Button';
import Modal from './Modal';
import FormInput from '../../../Shared/Components/FormInput/FormInput';

import './UserSettings.scss';

const SetNickname = () => {
  const { userInfo } = useUser();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [savedNickname, setSavedNickname] = useState<string>('');

  const handleSubmit = (dataItem: any) => {
    setSavedNickname(dataItem);
    setShowModal(!showModal);
    localStorage.removeItem('user');
    editUser(userInfo!.userId, { DisplayName: dataItem });
  };

  const inputValidator = (value: any) => (!value ? t('usersettings.set-nickname.required') : '');

  return (
    <div className="content-container">
      <div className="user-settings">
        <h1>{t('usersettings.set-nickname.title')}</h1>
        <p>{t('usersettings.set-nickname.description')}</p>

        {userInfo !== undefined && (
          <Form
            initialValues={{
              nickname: userInfo.nickname !== null ? userInfo.nickname : '',
            }}
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <FormElement style={{ position: 'relative' }}>
                <fieldset className="k-form-fieldset">
                  <Field
                    id="nickname"
                    name="nickname"
                    defaultValue={formRenderProps.valueGetter('nickname')}
                    component={FormInput}
                    validator={inputValidator}
                    label={t('usersettings.set-nickname.placeholder')}
                  />
                  <div className="k-form-buttons k-justify-content-end">
                    <div className="button-container button-container--center">
                      <Button
                        disabled={
                          !formRenderProps.allowSubmit ||
                          formRenderProps.valueGetter('nickname') === '' ||
                          savedNickname === formRenderProps.valueGetter('nickname') ||
                          formRenderProps.valueGetter('nickname') === userInfo.nickname ||
                          showModal === true
                        }
                        onClick={() => handleSubmit(formRenderProps.valueGetter('nickname'))}
                        label={t('usersettings.set-nickname.button')!}
                      />
                    </div>
                  </div>
                </fieldset>
              </FormElement>
            )}
          />
        )}
      </div>
      <Modal
        showModal={showModal}
        modalTitle={t('usersettings.modal.saved-title')}
        modalDescription={t('usersettings.modal.saved-description')}
        modalButton={t('usersettings.modal.saved-button')}
        onCloseModal={() => setShowModal(false)}
      />
    </div>
  );
};

export default SetNickname;
