import FilterOption from '../../../Models/Dashboard/FilterOption';
import http from '../../../Utils/HttpClient';

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const getTeam = async (teamId: string) => {
  const { data } = (await http.get(`${base}/Teams/${teamId}`)) as any;

  return [FilterOption.parse(data)];
};

const getAllTeams = async () => {
  const { data } = (await http.get(`${base}/Teams`)) as any;

  return data.value.map(FilterOption.parse);
};

export { getTeam, getAllTeams };
