import SurveyScore from '../../../Models/Dashboard/SurveyScore';
import http from '../../../Utils/HttpClient';
import IStatisticsRequestData from '../../../Models/Interfaces/IStatisticsRequestData';

const base = `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_SURVEYS}/Surveys`;

const getStatisticsEmployee = async (statisticsId: string) => {
  const { data } = (await http.post(`${base}/${statisticsId}/CalculateOwnSurveyScores`)) as any;

  return data ? [SurveyScore.parse(data)] : null;
};

const getCompareStatisticsEmployee = async (statisticsId: string, statisticsRequestData: IStatisticsRequestData) => {
  const transformedRequestData = {
    ...statisticsRequestData,
    DateFilters: statisticsRequestData.DateFilters?.map((dateFilter) => ({
      From: dateFilter?.from,
      To: dateFilter?.to,
    })),
  };

  const { data } = (await http.post(`${base}/${statisticsId}/CompareOwnSurveyScores`, transformedRequestData)) as any;

  return data.value ? data.value.map(SurveyScore.parse) : null;
};

export { getStatisticsEmployee, getCompareStatisticsEmployee };
