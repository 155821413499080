import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { useTranslation } from 'react-i18next';

import useAvailableSurveys from '../../../../Hooks/useAvailableSurveys';
import Loader from '../../../Loader/Loader';
import { SurveyShortInfo } from '../../../../Types/types';

const RoleMultiSelect = (fieldRenderProps: FieldRenderProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useAvailableSurveys();
  const { label, id, valid, disabled, wrapperStyle, value, onBlur, onChange, onFocus } = fieldRenderProps;

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id="category"
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className="k-form-field-wrap">
        {isLoading ? (
          <Loader />
        ) : (
          <MultiSelect
            data={data}
            value={value?.map((v: string) => data?.find(({ SurveyId }) => SurveyId === v))}
            textField="SurveyName"
            dataItemKey="SurveyId"
            placeholder={t('backoffice.multi-select.please-select') || ''}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(selection) => {
              // eslint-disable-next-line no-param-reassign
              selection.value = selection.value.map(({ SurveyId }: SurveyShortInfo) => SurveyId);
              onChange(selection);
            }}
          />
        )}
      </div>
    </FieldWrapper>
  );
};

export default RoleMultiSelect;
