import { useTranslation } from 'react-i18next';

import Button from '../../../Shared/Components/Button/Button';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';

import './ErrorHandling.scss';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />
      <div className="content-container">
        <div className="error-handling">
          <h1>{t('error.page-not-found')}</h1>
          <p>{t('error.page-not-found-description')}</p>
          <div className="buttons">
            <Button
              onClick={() => {
                window.location.href = '/dashboard';
              }}
              label={t('error.to-safety')!}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
