class RegisterModel {
  emailAddress: string;

  displayName?: string;

  organisationId: string;

  unitId?: string;

  teamId?: string;

  constructor(emailAddress: string, organisationId: string, unitId?: string, teamId?: string, displayName?: string) {
    this.emailAddress = emailAddress;
    this.organisationId = organisationId;
    this.unitId = unitId;
    this.teamId = teamId;
    this.displayName = displayName;
  }

  public static fromJson(json: any): RegisterModel {
    return new RegisterModel(json.EmailAddress, json.OrganisationId, json.UnitId, json.TeamId, json.DisplayName);
  }

  toJson(): any {
    return {
      EmailAddress: this.emailAddress,
      DisplayName: this.displayName,
      OrganisationId: this.organisationId,
      UnitId: this.unitId,
      TeamId: this.teamId,
    };
  }
}

export default RegisterModel;
