import { useState } from 'react';

import QuestionSlider from '../../../../Shared/Components/Slider/QuestionSlider';
import QuestionTitle from '../../../../Shared/Components/QuestionTitle/QuestionTitle';
import { SurveySliderQuestionType } from '../SurveyQuestionTypes';

import '../SurveyQuestion.scss';

const SliderQuestion = ({
  maxLabel,
  minLabel,
  maxValue,
  minValue,
  smallStepValue,
  question,
  questionKey,
  onAnswerSelect,
  previousAnswer,
  surveyId,
}: SurveySliderQuestionType) => {
  const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>(previousAnswer);

  const handleOnChange = (value: number) => {
    setSelectedAnswer(value);

    onAnswerSelect({
      numericValue: value,
      questionKey,
      questionLabel: question,
      surveyId,
    });
  };

  return (
    <div className="content-container">
      <div className="survey">
        <div className="survey-question">
          <div className="survey-question__title">
            <QuestionTitle title={question} />
          </div>
          <div className="survey-question__answers">
            <div className="survey-question__slider">
              <QuestionSlider
                previousAnswer={selectedAnswer}
                minLabel={minLabel}
                maxLabel={maxLabel}
                minValue={minValue}
                maxValue={maxValue}
                onChange={(value: number) => {
                  handleOnChange(value);
                }}
                smallStepValue={smallStepValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderQuestion;
