import { useEffect, useState } from 'react';
import useBem from '@frontend/use-bem';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Checkbox from '../../../../Shared/Components/Checkbox/Checkbox';
import Icon from '../../../../Shared/Enums/Icon';
import IconButton from '../../../../Shared/Components/Button/IconButton';
import { updateUser } from '../../../../Shared/Data/GetUserService';
import useUser from '../../../../Shared/Hooks/useUser';
import Loader from '../../../../Shared/Components/Loader/Loader';

import './SurveyNavigation.scss';
import PrivacyStatementTno from './PrivacyStatementTno';
import PrivacyStatement from './PrivacyStatement';

type PropType = {
  labelNext: string;
  onHandleNext: () => void;
  onHandlePrevious: () => void;
  firstQuestion: boolean;
  labelPrev: string;
  nextDisabled: boolean;
  lastQuestion: boolean;
  isPublic?: boolean;
  isSubmitting?: boolean;
};

const SurveyNavigation = ({
  labelPrev,
  labelNext,
  onHandleNext,
  onHandlePrevious,
  firstQuestion,
  nextDisabled,
  lastQuestion,
  isPublic = false,
  isSubmitting = false,
}: PropType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const user = useUser();
  const { bemClassName } = useBem('survey-navigation');
  const { bemClassName: bemClassNamePrivacy, bemClassNames: bemClassNamesPrivacy } = useBem('privacy-policy');
  const { t } = useTranslation();
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState<boolean>(false);

  let label = labelNext;

  if (firstQuestion) {
    label = t('survey.start');
  } else if (lastQuestion) {
    label = t('survey.submit');
  }

  const handleTermsAndConditions = () => {
    if (isPublic) {
      setAcceptedTermsAndConditions(!acceptedTermsAndConditions);
      localStorage.setItem('acceptedTerms', !acceptedTermsAndConditions ? 'true' : 'false');
    } else {
      if (!user?.userInfo?.userId) return;

      updateUser(user?.userInfo?.userId, { AcceptedTerms: !acceptedTermsAndConditions }).then(() => {
        setAcceptedTermsAndConditions(!acceptedTermsAndConditions);
        localStorage.setItem('acceptedTerms', !acceptedTermsAndConditions ? 'true' : 'false');
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('acceptedTerms')) {
      setAcceptedTermsAndConditions(localStorage.getItem('acceptedTerms') === 'true');

      return;
    }

    if (user?.userInfo) {
      setAcceptedTermsAndConditions(user.userInfo.acceptedTerms);
    }
  }, [user?.userInfo]);

  return (
    <div className="content-container">
      <div className={bemClassName()}>
        <div className={bemClassName('content')}>
          {firstQuestion && (
            <div
              className={bemClassNamesPrivacy([
                bemClassNamePrivacy(),
                bemClassNamePrivacy(null, 'tno', client === 'tno'),
              ])}
            >
              <Checkbox
                boxChecked={acceptedTermsAndConditions}
                onToggleChecked={() => handleTermsAndConditions()}
                label=""
                checkboxId="privacy"
              />
              <div className="privacy-policy__statements">
                {client === 'tno' ? <PrivacyStatementTno /> : <PrivacyStatement isPublic={isPublic} />}
              </div>
            </div>
          )}
          <div className="buttons">
            <button
              className={classNames('button--stripped', { 'button--stripped hide': firstQuestion })}
              onClick={onHandlePrevious}
              type="button"
            >
              {labelPrev}
            </button>
            <div className="submit-button">
              {isSubmitting && (
                <div className="loader">
                  <Loader />
                </div>
              )}
              <IconButton
                onClick={onHandleNext}
                icon={Icon.chevronRight}
                label={label}
                disabled={(!acceptedTermsAndConditions && firstQuestion) || nextDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyNavigation;
