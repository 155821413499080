class SurveyAvailability {
  isAvailable: boolean;

  lastSubmissionDate: number;

  surveyId: string;

  surveyTitle: string;

  constructor(isAvailable: boolean, lastSubmissionDate: number, surveyId: string, surveyTitle: string) {
    this.isAvailable = isAvailable;
    this.lastSubmissionDate = lastSubmissionDate;
    this.surveyId = surveyId;
    this.surveyTitle = surveyTitle;
  }

  public static parse(object: any) {
    return new SurveyAvailability(object.IsAvailable, object.LastSubmissionDate, object.SurveyId, object.SurveyTitle);
  }
}

export default SurveyAvailability;
