import FilterOption from '../../../Models/Dashboard/FilterOption';
import http from '../../../Utils/HttpClient';

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const getDivision = async (divisionId: string) => {
  const { data } = (await http.get(`${base}/Divisions/${divisionId}`)) as any;

  return [FilterOption.parse(data)];
};

const getAllDivisions = async () => {
  const { data } = (await http.get(`${base}/Divisions`)) as any;
  return data.value.map(FilterOption.parse);
};

export { getDivision, getAllDivisions };
