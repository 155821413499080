import { useQuery, UseQueryResult } from '@tanstack/react-query';
import SurveyScore from '../../../Models/Dashboard/SurveyScore';
import { getCompareStatisticsEmployer, getStatisticsEmployer } from '../Data/StatisticsEmployerService';

const useStatisticsEmployer = (filter: any): UseQueryResult<SurveyScore[], Error> => {
  const surveyId = window.__RUNTIME_CONFIG__.REACT_APP_SURVEY_ID;

  return useQuery(
    ['statistics', filter],
    () =>
      filter.Filter2.Id !== null
        ? getCompareStatisticsEmployer(surveyId, filter)
        : getStatisticsEmployer(surveyId, filter.Filter1),
    {
      staleTime: undefined,
    },
  );
};

export default useStatisticsEmployer;
