import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getCustomer } from '../Data/CustomerService';

const useCustomer = (customerId: string): UseQueryResult<FilterOption[], Error> =>
  useQuery(['Customer', customerId], () => getCustomer(customerId), {
    staleTime: undefined,
  });

export default useCustomer;
