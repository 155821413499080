class UserInfo {
  userObject: any;

  userId: string;

  customerId: string;

  organisationId: string;

  divisionId: string | null;

  businessUnitId: string | null;

  unitId: string | null;

  teamId: string | null;

  isActive: boolean;

  acceptedTerms: boolean;

  nickname: string;

  permissions: string[];

  constructor(
    userObject: any,
    userId: string,
    customerId: string,
    organisationId: string,
    divisionId: string | null,
    businessUnitId: string | null,
    unitId: string | null,
    teamId: string | null,
    isActive: boolean,
    acceptedTerms: boolean,
    nickname: string,
    permissions: string[],
  ) {
    this.userObject = userObject;
    this.userId = userId;
    this.customerId = customerId;
    this.organisationId = organisationId;
    this.divisionId = divisionId;
    this.businessUnitId = businessUnitId;
    this.unitId = unitId;
    this.teamId = teamId;
    this.isActive = isActive;
    this.acceptedTerms = acceptedTerms;
    this.nickname = nickname;
    this.permissions = permissions;
  }

  public static parse(
    userObject: any,
    userId: string,
    customerId: string,
    organisationId: string,
    divisionId: string | null,
    businessUnitId: string | null,
    unitId: string | null,
    teamId: string | null,
    isActive: boolean,
    acceptedTerms: boolean,
    nickname: string,
  ) {
    return new UserInfo(
      userObject,
      userId,
      customerId,
      organisationId,
      divisionId,
      businessUnitId,
      unitId,
      teamId,
      isActive,
      acceptedTerms,
      nickname,
      userObject.permissions,
    );
  }

  public static fromJson(userObject: any, json: any) {
    const user = JSON.parse(json);
    return this.parse(
      userObject,
      user.userId,
      user.customerId,
      user.organisationId,
      user.divisionId,
      user.businessUnitId,
      user.unitId,
      user.teamId,
      user.isActive,
      user.acceptedTerms,
      user.nickname,
    );
  }

  public checkPermission(permission: string) {
    if (this.permissions.find((p) => p === permission)) return true;

    return false;
  }
}

export default UserInfo;
