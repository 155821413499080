enum Level {
  ADMIN = 'Customers',
  CUSTOMER = 'Organisations',
  ORGANISATION = 'Divisions',
  DIVISION = 'BusinessUnits',
  BUSINESS_UNIT = 'Units',
  UNIT = 'Teams',
  USERS = 'Users',
}

export default Level;
