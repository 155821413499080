import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import UserInfo from '../../Models/User/UserInfo';
import { getUserByAuth0Id } from '../Data/GetUserService';

type Auth0JwtPayload = JwtPayload & {
  userInfo: UserInfo;
  ['https://wellbeingssd.nl/userId']: string;
};

const useUser = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  const processToken = async (token: string) => {
    const decoded: Auth0JwtPayload = await jwtDecode(token);

    if (localStorage.getItem('userSessionDuration') !== null) {
      if (DateTime.now().minus({ minutes: 15 }) > DateTime.fromISO(localStorage.getItem('userSessionDuration')!)) {
        localStorage.removeItem('user');
        localStorage.setItem('userSessionDuration', DateTime.now().toString());
      }
    } else {
      localStorage.removeItem('user');
      localStorage.setItem('userSessionDuration', DateTime.now().toString());
    }

    if (localStorage.getItem('user') !== null) {
      const localStorageUser: UserInfo = UserInfo.fromJson(decoded, localStorage.getItem('user')!);

      setUserInfo(localStorageUser);
      setAccessToken(token);
    } else {
      const retrievedUser = await getUserByAuth0Id(decoded.sub!);

      const {
        DisplayName,
        CustomerId,
        OrganisationId,
        DivisionId,
        BusinessUnitId,
        UnitId,
        TeamId,
        Id,
        IsActive,
        AcceptedTerms,
      } = retrievedUser;

      if (retrievedUser !== undefined) {
        const userData = UserInfo.parse(
          decoded,
          Id,
          CustomerId,
          OrganisationId,
          DivisionId,
          BusinessUnitId,
          UnitId,
          TeamId,
          IsActive,
          AcceptedTerms,
          DisplayName,
        );

        setUserInfo(userData);
        setAccessToken(token);
        localStorage.setItem('user', JSON.stringify(userData));
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setUserInfo(userInfo);
      return;
    }

    (async () => {
      try {
        // eslint-disable-next-line no-underscore-dangle
        const token = await getAccessTokenSilently({ audience: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_AUDIENCE });

        if (token) {
          processToken(token);
        }
      } catch (e: any) {
        throw new Error(e.message);
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently]);

  return {
    accessToken,
    userInfo,
    setUserInfo,
    user,
    processToken,
  };
};

export default useUser;
