import useBem from '@frontend/use-bem';

import { ErrorMessagesType } from './types';

import './ErrorMessages.scss';

const ErrorMessages = ({ errors, onCloseError }: ErrorMessagesType) => {
  const { bemClassName } = useBem('error-messages');

  return (
    <div className={bemClassName()}>
      {errors.map((error, index) => (
        <button
          key={error}
          className={bemClassName('error')}
          onClick={() => onCloseError(index)}
          type="button"
        >
          <span className="error-message">{error}</span>
        </button>
      ))}
    </div>
  );
};

export default ErrorMessages;
