import classNames from 'classnames';

import './FilterBarSelectButton.scss';

type PropType = {
  active: boolean;
  label: string;
  onClick: () => void;
};

const FilterBarSelectButton = ({ label, active, onClick }: PropType) => (
  <button
    type="button"
    className={classNames('filter-button', { 'filter-button--active': active })}
    title={label || undefined}
    onClick={(e: any): void => {
      e.preventDefault();
      onClick();
    }}
  >
    {label}
  </button>
);

export default FilterBarSelectButton;
