import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getCustomerById } from '../Data/CustomerService';
import Customer from '../../../Models/Customer/Customer';

const useCustomerById = (customerId: string): UseQueryResult<Customer, Error> =>
  useQuery(['Customers', customerId], () => getCustomerById(customerId), {
    staleTime: undefined,
  });

export default useCustomerById;
