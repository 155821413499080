import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider, SliderChangeEvent, SliderLabel } from '@progress/kendo-react-inputs';
import classNames from 'classnames';

import Icon from '../../Enums/Icon';
import IconRender from '../Icon/IconRender';

import './QuestionSlider.scss';

type QuestionSliderType = {
  maxLabel: string;
  minLabel: string;
  minValue: number;
  maxValue: number;
  onChange: (value: number) => void;
  smallStepValue: number;
  previousAnswer?: number;
};

const QuestionSlider = ({
  maxLabel,
  minLabel,
  minValue,
  maxValue,
  onChange,
  smallStepValue,
  previousAnswer = undefined,
}: QuestionSliderType) => {
  const [value, setValue] = useState<number | undefined>(previousAnswer);

  const { t } = useTranslation();

  return (
    <div className={classNames('question-slider', { 'question-slider--has-value': value })}>
      <div className="question-slider__label question-slider__label">
        {minLabel} ({minValue})
      </div>
      <div className="question-slider__slider">
        <div className="question-slider__container">
          <Slider
            value={value}
            step={smallStepValue}
            min={minValue}
            max={maxValue}
            defaultValue={previousAnswer}
            onChange={(event: SliderChangeEvent) => {
              const newValue = Math.round(event.value);
              setValue(newValue);
              onChange(newValue);
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
              <SliderLabel
                position={i}
                key={`tick_${i}`}
              />
            ))}
          </Slider>
          <div className="drag-hint">
            <div className="drag-hint__orb" />
            <div className="drag-hint__content">
              <IconRender
                icon={Icon.chevronLeft}
                width="12"
                height="8"
                color="var(--gray)"
              />
              {t('survey.drag-hint')}
              <IconRender
                icon={Icon.chevronRight}
                width="12"
                height="8"
                color="var(--gray)"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="question-slider__label question-slider__label">
        {maxLabel} ({maxValue})
      </div>

      <div className="question-slider--mobile">
        <div className="label">
          <p>
            {minLabel} ({minValue})
          </p>
        </div>
        <div className="label--right">
          <p>
            {maxLabel} ({maxValue})
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionSlider;
