import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import Button from '../../../Shared/Components/Button/Button';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';

import './ErrorHandling.scss';

const ErrorPage = () => {
  const { t } = useTranslation();
  const error: any = useRouteError();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />
      <div className="content-container">
        <div className="error-handling">
          <h1>{t('error.something-went-wrong')}</h1>
          <p>{t('error.something-went-wrong-description')}</p>

          <p className="error">
            <span>Error: {error.statusText || error.message}</span>
          </p>

          <div className="buttons">
            <Button
              onClick={() => {
                document.location.reload();
              }}
              label={t('error.reload')!}
            />
            <Button
              onClick={() => {
                window.location.href = '/';
              }}
              label={t('error.to-safety')!}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
