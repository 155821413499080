import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../../../Shared/Components/Loader/Loader';
import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import Level from '../../../Shared/Enums/Level';
import useUser from '../../../Shared/Hooks/useUser';
import useCustomer from '../../Dashboard/Hooks/useCustomer';
import ManagementPageHeader from './components/ManagementPageHeader/ManagementPageHeader';
import DataGridSelector from '../../../Shared/Components/DataGridSelector/DataGridSelector';
import { SelectedGridEnum } from '../../../Shared/Components/DataGridSelector/types';
import LayerDataGrid from '../../../Shared/Components/DataGrid/LayerDataGrid';

import '../management.scss';

const CustomerManagementLayer = () => {
  const { t } = useTranslation();
  const [selectedGrid, setSelectedGrid] = useState<SelectedGridEnum>(SelectedGridEnum.LayerDetails);
  const { userInfo } = useUser();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const { data: customer } = useCustomer(customerId!);

  // CHECK-RIGHT HR
  if (userInfo?.checkPermission('write:organisations:customer')) {
    return (
      <>
        <NavigationBar
          hasTitle
          hasBorder={false}
          menuTitle={t('dashboard.general.menu-title')}
          showProfile
        />
        <div className="content-container">
          <ManagementPageHeader
            title={customer ? customer[0].name : t('management.Organisations.title')}
            gridLayer={Level.CUSTOMER}
          />
          <DataGridSelector
            selectedGrid={selectedGrid}
            onGridSelect={(grid) => setSelectedGrid(grid)}
            gridLayer={Level.CUSTOMER}
            disableLinkedUsers
          />
          <LayerDataGrid
            gridLayer={Level.CUSTOMER}
            removePermission="write:organisations:customer"
          />
        </div>
      </>
    );
  }

  if (userInfo) {
    navigate('/dashboard');
  }

  return (
    <div className="loader-position">
      <Loader />
    </div>
  );
};

export default CustomerManagementLayer;
