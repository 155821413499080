import { Trans, useTranslation } from 'react-i18next';
import downloadFile from '../../../../Utils/DownloadFile';

const PrivacyStatementTno = () => {
  const { i18n } = useTranslation();

  const handleDownloadTermsAndConditionsTno = (): void => {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_TNO_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_TNO_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_TNO_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_TNO_NAME_EN,
      );
    }
  };

  const handleDownloadPrivacyStatement = (): void => {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NAME_EN,
      );
    }
  };

  return (
    <p>
      <Trans
        i18nKey="survey.terms-and-conditions"
        components={{
          1: <br />,
          2: (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              onClick={handleDownloadTermsAndConditionsTno}
              type="button"
            />
          ),
          3: (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              onClick={handleDownloadPrivacyStatement}
              type="button"
            />
          ),
        }}
      />
    </p>
  );
};

export default PrivacyStatementTno;
