import http from '../../../Utils/HttpClient';

interface Item {
  EmailAddress?: string | null;
  CustomerId?: string | null;
  OrganisationId?: string | null;
  DivisionId?: string | null;
  BusinessUnitId?: string | null;
  UnitId?: string | null;
  TeamId?: string | null;
  Roles?: string[];
  DisplayName?: string;
}

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const addUser = async (user: Item) => {
  const { data } = (await http.post(`${base}/Users/CreateUser`, user).then(() => {
    document.location.reload();
  })) as any;

  return data;
};

const editUser = async (userId: string, userData: Item) => {
  const { data } = await http.patch(`${base}/Users/${userId}`, userData);

  return data;
};

const deleteUser = async (userId: string) => {
  const { data } = (await http.delete(`${base}/Users/${userId}`)) as any;

  return data;
};

export { addUser, editUser, deleteUser };
