import http from '../../../Utils/HttpClient';

// eslint-disable-next-line no-underscore-dangle
const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const resetPassword = async (email: string) => {
  const { data } = await http.post(`${base}/Users/ResetPassword`, {
    EmailAddress: email,
  });

  return data;
};

export default resetPassword;
