import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getDivision } from '../Data/DivisionService';

const useDivision = (divisionId: string): UseQueryResult<FilterOption[], Error> =>
  useQuery(['Division', divisionId], () => getDivision(divisionId), {
    staleTime: undefined,
  });

export default useDivision;
