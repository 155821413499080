import SurveyText from '../Survey/SurveyText';

class SubdomainLabel {
  key: string;

  domainKey: string;

  subdomainLabel: SurveyText[];

  constructor(key: string, domainKey: string, subdomainLabel: SurveyText[]) {
    this.key = key;
    this.domainKey = domainKey;
    this.subdomainLabel = subdomainLabel;
  }

  public static parse(object: any) {
    return new SubdomainLabel(
      object.Key,
      object.DomainKey,
      object.LabelText.map((text: {}) => SurveyText.parse(text)),
    );
  }
}

export default SubdomainLabel;
