import FilterOption from '../../../Models/Dashboard/FilterOption';
import http from '../../../Utils/HttpClient';

const base = window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_CORE_ADMINISTRATION;

const getUnit = async (unitId: string) => {
  const { data } = (await http.get(`${base}/Units/${unitId}`)) as any;

  return [FilterOption.parse(data)];
};

const getAllUnits = async () => {
  const { data } = (await http.get(`${base}/Units`)) as any;
  return data.value.map(FilterOption.parse);
};

export { getUnit, getAllUnits };
