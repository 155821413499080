import { useTranslation } from 'react-i18next';
import Button from '../../../Shared/Components/Button/Button';

const UploadUsersInformation = () => {
  const { t } = useTranslation();

  function downloadFile(url: string, fileName: string) {
    fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  }

  function handleOnclick(): void {
    downloadFile(
      window.__RUNTIME_CONFIG__.REACT_APP_DOCUMENT_ROOT + window.__RUNTIME_CONFIG__.REACT_APP_EXCEL_PREVIEW_FILE,
      window.__RUNTIME_CONFIG__.REACT_APP_EXCEL_PREVIEW_FILE_NAME,
    );
  }

  return (
    <>
      <h3>{t('uploadUsers.extra-information')}</h3>
      <p>{t('uploadUsers.extra-information-description')}</p>

      <div className="step-by-styp-guide">
        <Button
          onClick={() => handleOnclick()}
          label={t('uploadUsers.download-template')!}
        />

        <div className="step">
          <img
            src="/images/step-by-step-guide/step-1.jpg"
            alt={t('uploadUsers.step-one-title')!}
          />
          <p>1. {t('uploadUsers.step-one')!}</p>
        </div>
        <div className="step">
          <img
            src="/images/step-by-step-guide/step-2.jpg"
            alt={t('uploadUsers.step-two-title')!}
          />
          <p>2. {t('uploadUsers.step-two')}</p>
        </div>
        <div className="step">
          <img
            src="/images/step-by-step-guide/step-3.jpg"
            alt={t('uploadUsers.step-three-title')!}
          />
          <p>3. {t('uploadUsers.step-three')}</p>
        </div>
        <div className="step">
          <img
            src="/images/step-by-step-guide/step-4.jpg"
            alt={t('uploadUsers.step-four-title')!}
          />
          <p>4. {t('uploadUsers.step-four')}</p>
        </div>
      </div>
    </>
  );
};

export default UploadUsersInformation;
