import SurveyQuestionType from './SurveyQuestionType';
import SurveyText from './SurveyText';
import SurveyQuestion from './SurveyQuestion';

class SurveyOpenQuestion extends SurveyQuestion {
  key: string;

  isRequired: boolean;

  labelText: SurveyText[];

  hintText: SurveyText[];

  type: SurveyQuestionType;

  constructor(
    key: string,
    isRequired: boolean,
    labelText: SurveyText[],
    hintText: SurveyText[],
    surveyQuestionType: SurveyQuestionType,
    domain: string,
    subdomain: string,
    weight: number
  ) {
    super(key, isRequired, labelText, hintText, surveyQuestionType, domain, subdomain, weight);

    this.key = key;
    this.isRequired = isRequired;
    this.labelText = labelText;
    this.hintText = hintText;
    this.type = surveyQuestionType;
  }

  public static parse(object: any) {
    return new SurveyOpenQuestion(
      object.Key,
      object.IsRequired,
      object.LabelText.map((text: {}) => SurveyText.parse(text)),
      object.HintText.map((text: {}) => SurveyText.parse(text)),
      object.QuestionType,
      object.QuestionDomain,
      object.QuestionSubdomain,
      object.WeightDomain,
    );
  }
}

export default SurveyOpenQuestion;
