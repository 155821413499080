import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../Models/Dashboard/FilterOption';
import getAllTeams from '../Data/TeamService';

const useTeam = (): UseQueryResult<FilterOption[], Error> =>
  useQuery(['teams'], () => getAllTeams(), {
    staleTime: undefined,
  });

export default useTeam;
