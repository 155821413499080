import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { useTranslation } from 'react-i18next';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { UserDataType } from '../../types';
import TeamDropdownList from '../DropdownList/TeamDropdownList';
import RoleMultiSelect from '../DropdownList/RoleMultiSelect';
import useUser from '../../../../Hooks/useUser';

interface EditFormProps {
  cancelEdit: () => void;
  onSubmit: (event: any) => void;
  item: UserDataType;
}

const EditForm = ({ item, cancelEdit, onSubmit }: EditFormProps) => {
  const { userInfo } = useUser();
  const { EmailAddress, Id } = item;
  const { t } = useTranslation();
  const emailRegex = /\S+@\S+\.\S+/;
  const emailValidator = (value: string) => (emailRegex.test(value) ? '' : t('backoffice.errors.invalid-email'));
  const rolesValidator = (value: string[]) => (value && value?.length > 0 ? '' : t('backoffice.errors.required-field'));

  return (
    <Dialog
      className="pit-edit-form"
      title={EmailAddress ? `${t('backoffice.generic.manage')} ${EmailAddress}` : `${t('backoffice.generic.new-user')}`}
      onClose={cancelEdit}
      width={700}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={({ allowSubmit }) => (
          <FormElement style={{ maxWidth: 650 }}>
            <fieldset className="k-form-fieldset">
              <div className="mb-3">
                <Label>{t('backoffice.user.email')}</Label>
                <div className="k-form-field">
                  <Field
                    name="EmailAddress"
                    component={Input}
                    validator={emailValidator}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Label>{t('backoffice.user.team')}</Label>
                <p>{t('backoffice.user.team-description')}</p>
                <div className="k-form-field">
                  <Field
                    name="TeamId"
                    component={TeamDropdownList}
                  />
                </div>
              </div>
              {userInfo && userInfo.checkPermission('write:organisations:customer') && (
                <div className="mb-3">
                  <Label>{t('backoffice.user.roles')}</Label>
                  <Field
                    name="Roles"
                    value
                    component={RoleMultiSelect}
                    validator={rolesValidator}
                  />
                </div>
              )}
              {userInfo && Id && userInfo.checkPermission('write:customers:all') && (
                <div className="mb-3">
                  <Label>{t('backoffice.user.active')}</Label>
                  <div className="k-form-field">
                    <Field
                      name="IsActive"
                      value
                      component={Checkbox}
                    />
                  </div>
                </div>
              )}
            </fieldset>
            <div className="k-form-buttons">
              <button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!allowSubmit}
              >
                {t('backoffice.generic.update')}
              </button>
              <button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={cancelEdit}
              >
                {t('backoffice.generic.cancel')}
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default EditForm;
