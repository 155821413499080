import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import EditTeam from '../Components/EditTeam';

const EditTeamPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />

      <EditTeam redirectOnSave={pathname === '/complete-profile'} />
    </>
  );
};

export default EditTeamPage;
