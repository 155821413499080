import SurveyText from './SurveyText';

class SurveyIntroductionStep {
  explanationText: SurveyText[];

  headingText: SurveyText[];

  subHeadingText: SurveyText[];

  constructor(explanationText: SurveyText[], headingText: SurveyText[], subheadingText: SurveyText[]) {
    this.explanationText = explanationText;
    this.headingText = headingText;
    this.subHeadingText = subheadingText;
  }

  public static parse(object: any) {
    return new SurveyIntroductionStep(
      object.ExplanationText.map((text: {}) => SurveyText.parse(text)),
      object.HeadingText.map((text: {}) => SurveyText.parse(text)),
      object.SubHeadingText.map((text: {}) => SurveyText.parse(text)),
    );
  }
}

export default SurveyIntroductionStep;
