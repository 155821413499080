import SurveySection from './SurveySection';
import SurveyIntroductionStep from './SurveyIntroductionStep';
import SurveyOutroStep from './SurveyOutroStep';
import SurveyResponseMultiplicity from './SurveyResponseMultiplicity';
import SurveyQuestionTheme from './SurveyQuestionTheme';
import SurveyQuestionDomain from './SurveyQuestionDomain';

class SurveySpecification {
  sections: SurveySection[];

  defaultLanguage: string;

  introductionStep: SurveyIntroductionStep;

  outroStep: SurveyOutroStep;

  supportedLanguages: string[];

  responseMultiplicity: SurveyResponseMultiplicity;

  questionThemes: SurveyQuestionTheme[];

  questionDomains: SurveyQuestionDomain[];

  constructor(
    sections: SurveySection[],
    defaultLanguage: string,
    introductionStep: SurveyIntroductionStep,
    outroStep: SurveyOutroStep,
    supportedLanguages: string[],
    responseMultiplicity: SurveyResponseMultiplicity,
    questionThemes: SurveyQuestionTheme[],
    questionDomains: SurveyQuestionDomain[],
  ) {
    this.sections = sections;
    this.defaultLanguage = defaultLanguage;
    this.introductionStep = introductionStep;
    this.outroStep = outroStep;
    this.supportedLanguages = supportedLanguages;
    this.responseMultiplicity = responseMultiplicity;
    this.questionThemes = questionThemes;
    this.questionDomains = questionDomains;
  }

  public static fromJson(json: string): SurveySpecification {
    const specification = JSON.parse(json);
    return new SurveySpecification(
      specification.Sections.map((section: {}) => SurveySection.parse(section)),
      specification.DefaultLanguage,
      SurveyIntroductionStep.parse(specification.IntroductionStep),
      SurveyOutroStep.parse(specification.OutroStep),
      specification.SupportedLanguages,
      specification.ResponseMultiplicity,
      specification.QuestionThemes,
      specification.QuestionDomains,
    );
  }
}

export default SurveySpecification;
