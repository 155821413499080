import { Trans, useTranslation } from 'react-i18next';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import BackButton from '../Components/BackButton';

import '../About.scss';

const AboutUsPage = () => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />

      <div className="content-container">
        <div className="dashboard-header">
          <div className="dashboard-header__back">
            <BackButton />
          </div>
          <h1 className="dashboard-header__title dashboard-header__title--wide">
            <span>{t('information.aboutUs.title')}</span>
          </h1>
        </div>
        <div className="overview__intro no-border">
          <div className="paragraph">
            <Trans
              i18nKey="information.aboutUs.text"
              components={{
                1: (
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/35897299/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                  </a>
                ),
              }}
            />
          </div>
          <div className="paragraph">
            <p>{t('information.aboutUs.text-2')}</p>
          </div>

          <div className="paragraph">
            <p>{t('information.aboutUs.text-3')}</p>
          </div>

          <div className="logo-grid">
            {client !== 'tno' && (
              <div className="logo-grid__item">
                <img
                  src="/images/logos/about/logo-abnamro.svg"
                  alt="Logo"
                />
              </div>
            )}
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-achmea.jpg"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-amsterdam.png"
                alt="Logo"
              />
            </div>
            {client !== 'tno' && (
              <div className="logo-grid__item">
                <img
                  src="/images/logos/about/logo-asml.svg"
                  alt="Logo"
                />
              </div>
            )}
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-deloitte.jpg"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-dsm.png"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-equinix.png"
                alt="Logo"
              />
            </div>
            {client !== 'tno' && (
              <div className="logo-grid__item">
                <img
                  src="/images/logos/about/logo-friesland.svg"
                  alt="Logo"
                />
              </div>
            )}
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-ggz.png"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-kpn.png"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-ministerie.png"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-p-direkt.jpg"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-philadelphia.png"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-philips.jpg"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-rabobank.jpg"
                alt="Logo"
              />
            </div>
            {client !== 'tno' && (
              <div className="logo-grid__item">
                <img
                  src="/images/logos/about/logo-randstad.svg"
                  alt="Logo"
                />
              </div>
            )}
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-tatasteel.png"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-uwv.png"
                alt="Logo"
              />
            </div>
            {client !== 'tno' && (
              <div className="logo-grid__item">
                <img
                  src="/images/logos/about/logo-unilever.svg"
                  alt="Logo"
                />
              </div>
            )}
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-vattenfall.png"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-wenb.png"
                alt="Logo"
              />
            </div>
            <div className="logo-grid__item">
              <img
                src="/images/logos/about/logo-zk.png"
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
