import { DateTime } from 'luxon';
import i18n from '../../i18n';

class PeriodFilterOption {
  label: string;

  value: DateTime;

  from: DateTime;

  to: DateTime;

  constructor(label: string, from: DateTime, to: DateTime) {
    this.label = label;
    this.value = from;
    this.from = from;
    this.to = to;
  }

  public static parse(object: any, personal: boolean = false) {
    const date = DateTime.fromISO(object);
    const until = personal ? 'day' : 'month';
    const format = personal ? 'dd MMMM yyyy' : 'MMMM yyyy';

    return {
      label: date.toFormat(format, { locale: i18n.language }),
      value: date,
      from: date,
      to: date.plus({ [until]: 1 }),
    };
  }

  public static parseList(object: any[], personal: boolean = false) {
    return object.map((item) => this.parse(item, personal));
  }
}

export default PeriodFilterOption;
