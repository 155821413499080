import SurveyText from './SurveyText';

class SurveyChoiceOption {
  defaultText: string;

  key: string;

  labelText: SurveyText[];

  constructor(defaultText: string, key: string, labelText: SurveyText[]) {
    this.defaultText = defaultText;
    this.key = key;
    this.labelText = labelText;
  }

  public static parse(object: any) {
    return new SurveyChoiceOption(
      object.DefaultText,
      object.Key,
      object.LabelText.map((text: {}) => SurveyText.parse(text)),
    );
  }
}

export default SurveyChoiceOption;
