import http from '../../../Utils/HttpClient';
import PeriodOptions from '../../../Models/Dashboard/PeriodOptions';
import FilterLevel from '../../../Shared/Enums/FilterLevel';

const base = `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_SURVEYS}/Surveys/`;

const getPeriods = async (surveyId: string, filterData: { Level: FilterLevel; Id: string } | {}) => {
  const { data } = (await http.post(`${base}${surveyId}/ResponseMonths`, filterData)) as any;

  return PeriodOptions.parse(data);
};

export default getPeriods;
