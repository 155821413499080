import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getAllUnits } from '../Data/UnitService';

const useUnits = (): UseQueryResult<FilterOption[], Error> =>
  useQuery(['Units'], () => getAllUnits(), {
    staleTime: undefined,
  });

export default useUnits;
