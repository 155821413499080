import { useTranslation } from 'react-i18next';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import BackButton from '../Components/BackButton';

import '../About.scss';

const OurMission = () => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar
        hasTitle
        hasBorder={false}
        menuTitle={t('dashboard.general.menu-title')}
        showProfile
      />

      <div className="content-container">
        <div className="dashboard-header">
          <div className="dashboard-header__back">
            <BackButton />
          </div>
          <h1 className="dashboard-header__title dashboard-header__title--wide">
            <span>{t('information.ourMission.title')}</span>
          </h1>
        </div>
        <div className="overview__intro no-border">
          <div className="paragraph">
            <h3>{t('information.ourMission.subtitle')}</h3>
            <p>{t('information.ourMission.text')}</p>
          </div>
          <div className="paragraph">
            <p>{t('information.ourMission.text-2')}</p>
          </div>
          <div className="paragraph">
            <p>{t('information.ourMission.text-3')}</p>
          </div>
          <div className="paragraph">
            <p>{t('information.ourMission.text-4')}</p>
          </div>
          {client === 'tno' && (
            <div className="paragraph">
              <p>{t('information.ourMission.text-5')}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OurMission;
