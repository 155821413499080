import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import useBem from '@frontend/use-bem';
import { useNavigate, useParams } from 'react-router-dom';

import NavigationBar from '../../../Shared/Components/Navigation/NavigationBar';
import RegisterModel from '../data/RegisterModel';
import SignupTokenType from '../types';
import Button from '../../../Shared/Components/Button/Button';
import FormInput from '../../../Shared/Components/FormInput/FormInput';
import signup from '../data/SignupService';
import Loader from '../../../Shared/Components/Loader/Loader';
import Footer from '../../../Shared/Components/Footer/FooterRow';

import './signup.scss';

const SignupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.__RUNTIME_CONFIG__.REACT_APP_CLIENT !== 'tno') {
      navigate('/');
    }
  }, []);

  const { bemClassName } = useBem('user-settings');
  const { signupToken } = useParams();
  const { organisationId, unitId, teamId } = JSON.parse(atob(signupToken!)) as SignupTokenType;
  const [loading, setLoading] = useState<boolean>(false);
  const [constraintError, setConstraintError] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [validationDomainError, setValidationDomainError] = useState<boolean>(false);
  const [validationDomains, setValidationDomains] = useState<string>('');

  const handleSubmit = async (email: string, displayName?: string) => {
    setLoading(true);
    const data = new RegisterModel(email, organisationId, unitId, teamId, displayName);
    try {
      await signup(data.toJson());
      navigate('/signup/successful');
    } catch (error: any) {
      const {
        data: { status, title },
      } = error;
      setConstraintError(false);
      setValidationError(false);
      setValidationDomainError(false);

      if (status === 409) setConstraintError(true);
      if (status === 422) {
        if (title.includes('|')) {
          setValidationDomains(title.split('|')[1]);
          setValidationDomainError(true);
        } else {
          setValidationError(true);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="full-height">
      <NavigationBar
        menuTitle=""
        showContentButtons={false}
      />
      <div className={bemClassName()}>
        <div className="content-container">
          <div className={bemClassName()}>
            <h1 className={bemClassName('title')}>{t('register.title')}</h1>
            <p className={bemClassName('title')}>{t('register.description')}</p>

            <Form
              initialValues={{ email: '', displayName: '' }}
              render={(formRenderProps) => (
                <FormElement style={{ position: 'relative' }}>
                  <fieldset className="k-form-fieldset">
                    <Field
                      id="email"
                      name="email"
                      defaultValue={formRenderProps.valueGetter('email')}
                      component={FormInput}
                      label={t('register.email')}
                    />
                    <Field
                      id="displayName"
                      name="displayName"
                      defaultValue={formRenderProps.valueGetter('displayName')}
                      component={FormInput}
                      label={t('register.display-name')}
                    />
                    <div className="error">
                      {constraintError && <p>{t('register.error.constraint-violation')}</p>}
                      {validationError && <p>{t('register.error.validation-violation')}</p>}
                      {validationDomainError && (
                        <p>{t('register.error.validation-violation-domains', { domains: validationDomains })}</p>
                      )}
                    </div>
                    <div className="k-form-buttons k-justify-content-end">
                      <div className="button-container button-container--center">
                        {loading && <Loader />}
                        {!loading && (
                          <Button
                            disabled={!formRenderProps.allowSubmit || formRenderProps.valueGetter('displayName') === ''}
                            onClick={() =>
                              handleSubmit(
                                formRenderProps.valueGetter('email'),
                                formRenderProps.valueGetter('displayName'),
                              )
                            }
                            label={t('register.submit')!}
                          />
                        )}
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignupPage;
