import { useQuery, UseQueryResult } from '@tanstack/react-query';
import SurveyAvailability from '../../../Models/Survey/SurveyAvailability';
import getAvailability from '../Data/AvailabilityService';

const useAvailability = (): UseQueryResult<SurveyAvailability[], Error> =>
  useQuery(['availability'], () => getAvailability(), {
    staleTime: undefined,
  });

export default useAvailability;
