import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useUser from '../../../../Hooks/useUser';

import '@progress/kendo-theme-default/dist/all.css';
import './Breadcrumbs.scss';

type PropType = {
  title: string;
};

const Breadcrumbs = ({ title }: PropType) => {
  const { customerId, organisationId, divisionId, businessUnitId, unitId, teamId } = useParams();
  const { userInfo } = useUser();
  const { t } = useTranslation();

  return (
    <div className="breadcrumbs">
      {userInfo && userInfo.checkPermission('write:customers:all') && customerId !== undefined && (
        <>
          <a
            className="breadcrumbs__item"
            href="/customers/"
          >
            {t(`management.Customers.title`)}
          </a>
          <span className="k-icon k-i-arrow-chevron-right" />
        </>
      )}
      {organisationId !== undefined && (
        <>
          <a
            className="breadcrumbs__item"
            href={`/backoffice/${customerId}/`}
          >
            {t(`management.Organisations.title`)}
          </a>
          <span className="k-icon k-i-arrow-chevron-right" />
        </>
      )}
      {divisionId !== undefined && (
        <>
          <a
            className="breadcrumbs__item"
            href={`/backoffice/${customerId}/${organisationId}`}
          >
            {t(`management.Divisions.title`)}
          </a>
          <span className="k-icon k-i-arrow-chevron-right" />
        </>
      )}
      {businessUnitId !== undefined && (
        <>
          <a
            className="breadcrumbs__item"
            href={`/backoffice/${customerId}/${organisationId}/${divisionId}`}
          >
            {t(`management.BusinessUnits.title`)}
          </a>
          <span className="k-icon k-i-arrow-chevron-right" />
        </>
      )}
      {unitId !== undefined && (
        <>
          <a
            className="breadcrumbs__item"
            href={`/backoffice/${customerId}/${organisationId}/${divisionId}/${businessUnitId}`}
          >
            {t(`management.Units.title`)}
          </a>
          <span className="k-icon k-i-arrow-chevron-right" />
        </>
      )}
      {teamId !== undefined && (
        <>
          <a
            className="breadcrumbs__item"
            href={`/backoffice/${customerId}/${organisationId}/${divisionId}/${businessUnitId}/${unitId}`}
          >
            {t(`management.Teams.title`)}
          </a>
          <span className="k-icon k-i-arrow-chevron-right" />
        </>
      )}
      {title}
    </div>
  );
};

export default Breadcrumbs;
