import Level from '../../Enums/Level';

export type DataGridSelectorType = {
  selectedGrid: SelectedGridEnum;
  onGridSelect: (grid: SelectedGridEnum) => void;
  gridLayer: Level;
  disableLinkedUsers?: boolean;
  disableSubLayers?: boolean;
};

export enum SelectedGridEnum {
  LayerDetails = 'layerDetails',
  LinkedUsers = 'linkedUsers',
}
