import { useEffect, useState } from 'react';
import useBem from '@frontend/use-bem';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '../../../Shared/Components/Button/Button';
import ButtonTheme from '../../../Shared/Enums/ButtonTheme';

import './Modal.scss';

type PropType = {
  showModal?: boolean;
  logoutTrigger?: boolean;
  modalTitle: string;
  modalDescription: string;
  modalButton: string;
  closeButton?: string;
  showCloseButton?: boolean;
  onHandleClick?: () => void;
  actionButtonTheme?: ButtonTheme;
  onCloseModal: () => void;
};

const LogoutModal = ({
  modalTitle,
  modalDescription,
  modalButton,
  closeButton = 'Close',
  showCloseButton = false,
  onHandleClick = () => {},
  actionButtonTheme = ButtonTheme.NORMAL,
  onCloseModal,
  showModal = false,
  logoutTrigger = false,
}: PropType) => {
  const { bemClassName } = useBem('modal');
  const { logout } = useAuth0();
  const [modalActive, setModalActive] = useState<boolean>(showModal);

  function handleClick() {
    if (logoutTrigger) {
      logout({ returnTo: window.location.origin });
    } else {
      setModalActive(false);
      onHandleClick();
      onCloseModal();
    }
  }

  useEffect(() => {
    setModalActive(showModal);
  }, [showModal]);

  return (
    <div className={bemClassName()}>
      {modalActive && (
        <div className={bemClassName('background')}>
          <div className={bemClassName('content')}>
            <h2>{modalTitle}</h2>
            <p>{modalDescription}</p>
            <div className={bemClassName('button-container')}>
              {showCloseButton && (
                <Button
                  theme={ButtonTheme.TEXT}
                  onClick={() => onCloseModal()}
                  label={closeButton}
                />
              )}
              <Button
                onClick={() => handleClick()}
                label={modalButton}
                theme={actionButtonTheme}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogoutModal;
