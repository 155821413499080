class SurveyText {
  culture: string;

  language: string;

  value: string;

  constructor(culture: string, language: string, value: string) {
    this.culture = culture;
    this.language = language;
    this.value = value;
  }

  public static parse(object: any) {
    return new SurveyText(object.Culture, object.Language, object.Value);
  }
}

export default SurveyText;
