class SurveyPostAnswer {
  QuestionKey: string;

  OptionKey: string | null;

  Value: string | number;

  constructor(QuestionKey: string, OptionKey: string | null, Value: string | number) {
    this.QuestionKey = QuestionKey;
    this.OptionKey = OptionKey;
    this.Value = Value;
  }

  public static parse(object: any) {
    if (object.textValue !== undefined) {
      return new SurveyPostAnswer(object.questionKey, object.optionKey, object.textValue);
    }
    return new SurveyPostAnswer(object.questionKey, object.optionKey, object.numericValue);
  }
}

export default SurveyPostAnswer;
