import SurveyQuestionType from './SurveyQuestionType';
import SurveyText from './SurveyText';

class SurveyQuestion {
  key: string;

  isRequired: boolean;

  labelText: SurveyText[];

  hintText: SurveyText[];

  type: SurveyQuestionType;

  domain: string;

  subdomain: string;

  weight: number;

  constructor(
    key: string,
    isRequired: boolean,
    labelText: SurveyText[],
    hintText: SurveyText[],
    type: SurveyQuestionType,
    domain: string,
    subdomain: string,
    weight: number,
  ) {
    this.key = key;
    this.isRequired = isRequired;
    this.labelText = labelText;
    this.hintText = hintText;
    this.type = type;
    this.domain = domain;
    this.subdomain = subdomain;
    this.weight = weight;
  }

  public static parse(object: any) {
    return new SurveyQuestion(
      object.Key,
      object.IsRequired,
      object.LabelText.map((text: {}) => SurveyText.parse(text)),
      object.HintText.map((text: {}) => SurveyText.parse(text)),
      object.QuestionType,
      object.QuestionDomain,
      object.QuestionSubdomain,
      object.WeightDomain,
    );
  }
}

export default SurveyQuestion;
