import { useQuery, UseQueryResult } from '@tanstack/react-query';
import FilterOption from '../../../Models/Dashboard/FilterOption';
import { getOrganisation } from '../Data/OrganisationService';

const useOrganisation = (organisationId: string): UseQueryResult<FilterOption[], Error> =>
  useQuery(['organisation', organisationId], () => getOrganisation(organisationId), {
    staleTime: undefined,
  });

export default useOrganisation;
