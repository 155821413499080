import { cloneElement, ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridRowProps,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { CompositeFilterDescriptor, DataResult, State } from '@progress/kendo-data-query';

import { LayerDataGridType, UserDataType } from './types';
import DataLoader from './components/DataLoader';
import Level from '../../Enums/Level';
import getFilterLevel from '../../../Features/Management/Pages/functions/getFilterLevel';
import { addLayerItem, deleteLayerItem, editLayerItem } from '../../../Features/Management/Data/ManagerService';
import EditCell from './components/DataGridCells/EditCell';
import NameCell from './components/DataGridCells/NameCell';
import SignupTokenType from '../../../Features/Signup/types';
import EditLayerForm from './components/EditLayerForm/EditLayerForm';
import Modal from './components/DataGridModal/Modal';
import ErrorMessages from '../ErrorMessage/ErrorMessages';

import './DataGrid.scss';
import SurveysCell from './components/DataGridCells/SurveysCell';

const LayerDataGrid = ({ gridLayer, removePermission }: LayerDataGridType) => {
  const client = window.__RUNTIME_CONFIG__.REACT_APP_CLIENT;
  const { t } = useTranslation();
  const [showGenerateLinkModal, setShowGenerateLinkModal] = useState<boolean>(false);
  const [selectedLayerItem, setSelectedLayerItem] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [generatedLink, setGeneratedLink] = useState<string>('');
  const [itemTitle, setItemTitle] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { customerId, organisationId, divisionId, businessUnitId, unitId } = useParams();

  const [layerItems, setLayerItems] = useState<DataResult>({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = useState<State>({
    take: 25,
    skip: 0,
    filter: getFilterLevel({ gridLayer }) as CompositeFilterDescriptor,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataResult: DataResult) => {
    setLayerItems(dataResult);
  };

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<UserDataType>();

  const enterEdit = (item: UserDataType) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const saveAction = async (layerItem: any) => {
    let query = {};

    switch (gridLayer) {
      case Level.ADMIN:
        query = { Name: layerItem.Name };
        break;
      case Level.CUSTOMER:
        query = { Name: layerItem.Name, CustomerId: customerId };
        break;
      case Level.ORGANISATION:
        query = { Name: layerItem.Name, OrganisationId: organisationId };
        break;
      case Level.DIVISION:
        query = { Name: layerItem.Name, DivisionId: divisionId };
        break;
      case Level.BUSINESS_UNIT:
        query = { Name: layerItem.Name, BusinessUnitId: businessUnitId };
        break;
      case Level.UNIT:
        query = { Name: layerItem.Name, Code: layerItem.Code, UnitId: unitId };
        break;
      default:
        throw new Error('Invalid level');
    }

    const result = await addLayerItem(gridLayer, query);
    // @ts-ignore
    delete result.data['@odata.context'];
    layerItems.data.unshift(result.data);

    setLayerItems({ ...layerItems });
    setOpenForm(false);
  };

  const updateAction = async (layerItem: any) => {
    if (gridLayer && layerItem) {
      await editLayerItem(gridLayer, layerItem.Id, layerItem)
        .catch((error) => {
          if (error.status !== 204 || error.status !== 200) {
            setErrorMessages([t('backoffice.errors.generic-layer-item-error')]);
          }
        })
        .then((response) => {
          if (response === '') {
            layerItems.data = layerItems.data.map((item) => {
              if (layerItem.Id === item.Id) {
                // eslint-disable-next-line no-param-reassign
                item = { ...layerItem };
              }
              return item;
            });
            setLayerItems({ ...layerItems });
            setOpenForm(false);
          }
        });
    }
  };

  const handleSubmit = async (layerItem: UserDataType) => {
    if (!layerItem.Id) {
      await saveAction(layerItem);
    } else {
      await updateAction(layerItem);
    }
  };

  const addNew = () => {
    setEditItem({} as UserDataType);
    setOpenForm(true);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const triggerDestroyItemModal = async (layerItem: any) => {
    if (gridLayer && layerItem.Id) {
      setShowDeleteModal(true);
      setSelectedLayerItem(layerItem);
    }
  };

  const destroyItem = async (layerItem: any) => {
    if (gridLayer && layerItem.Id) {
      const result = await deleteLayerItem(gridLayer, layerItem.Id!);

      if (result.status === 204) {
        const index = layerItems.data.findIndex((item: any) => item.Id === layerItem.Id);
        layerItems.data = layerItems.data.splice(index, 1);
        setLayerItems({ ...layerItems });
      }
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const LayerClickableNameCell = (gridCellProps: GridCellProps) => (
    <NameCell
      gridCellProps={gridCellProps}
      level={gridLayer}
    />
  );

  const handleGenerateLink = async (item: any) => {
    const signupToken: SignupTokenType = {
      organisationId: organisationId!,
    };

    switch (gridLayer) {
      case Level.CUSTOMER:
        signupToken.organisationId = item.Id!;
        break;
      case Level.ORGANISATION:
        signupToken.organisationId = organisationId!;
        signupToken.divisionId = item.Id!;
        break;
      case Level.DIVISION:
        signupToken.organisationId = organisationId!;
        signupToken.divisionId = divisionId;
        signupToken.businessUnitId = item.Id!;
        break;
      case Level.BUSINESS_UNIT:
        signupToken.organisationId = organisationId!;
        signupToken.divisionId = divisionId;
        signupToken.businessUnitId = businessUnitId;
        signupToken.unitId = item.Id!;
        break;
      case Level.UNIT:
      default:
        signupToken.organisationId = organisationId!;
        signupToken.divisionId = divisionId;
        signupToken.businessUnitId = businessUnitId;
        signupToken.unitId = unitId;
        signupToken.teamId = item.Id!;
    }

    const root = window.__RUNTIME_CONFIG__.REACT_APP_ROOT;
    const encoded = `${root}signup/${btoa(JSON.stringify(signupToken))}`;

    setItemTitle(item.Name!);
    setGeneratedLink(encoded);
    setShowGenerateLinkModal(true);

    await navigator.clipboard.writeText(encoded);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const LayerEditCell = (props: GridCellProps) => (
    <EditCell
      {...props}
      enterEdit={enterEdit}
      gridLayer={gridLayer}
      destroyItem={triggerDestroyItemModal}
      generateLink={(dataItem) => handleGenerateLink(dataItem)}
      removePermission={removePermission}
    />
  );

  const rowRender = (
    trElement: ReactElement<HTMLTableRowElement>,
    { dataItem: { LastRunSuccessfull } }: GridRowProps,
  ) => {
    const trProps: any = { className: LastRunSuccessfull ? '' : 'error-record' };
    return cloneElement(trElement, { ...trProps }, trElement.props.children as ReactNode);
  };

  return (
    <>
      <Grid
        filterable
        sortable
        pageable={{
          buttonCount: 8,
          pageSizes: [25, 50, 100],
        }}
        {...dataState}
        data={layerItems}
        onDataStateChange={dataStateChange}
        rowRender={rowRender}
      >
        <GridToolbar>
          <button
            title={t(`management.${gridLayer}.add-new`)!}
            className="k-grid-button"
            onClick={addNew}
            type="button"
            aria-label={t(`management.${gridLayer}.add-new`)!}
          >
            {t(`management.${gridLayer}.add-new`)!}
          </button>
        </GridToolbar>
        <GridColumn
          field="Name"
          title={t(`management.${gridLayer}.title`)!}
          cell={LayerClickableNameCell}
        />
        {gridLayer === Level.ADMIN && client !== 'TNO' && (
          <GridColumn
            field="FeedbackUrl"
            title={t(`management.${gridLayer}.feedback`)!}
          />
        )}
        {gridLayer === Level.ADMIN && client !== 'TNO' && (
          <GridColumn
            field="SurveyIds"
            title={t(`management.${gridLayer}.surveys`)!}
            cell={SurveysCell}
          />
        )}
        {gridLayer === Level.UNIT && (
          <GridColumn
            field="Code"
            title={t(`management.${gridLayer}.code`)!}
          />
        )}
        <GridColumn
          title={t(`management.actions`) || ''}
          filterable={false}
          width={client === 'tno' ? 380 : 270}
          cell={LayerEditCell}
        />
      </Grid>
      <DataLoader
        gridLayer={gridLayer}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
      {openForm && (
        <EditLayerForm
          gridLayer={gridLayer}
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem!}
        />
      )}
      <Modal
        showModal={showGenerateLinkModal}
        title={t('management.generate-link.title', { name: itemTitle })}
        description={generatedLink}
        cancelButton={t('management.generate-link.dismiss')}
        onCancelDeletion={() => setShowGenerateLinkModal(false)}
        isCopy
      />
      <Modal
        showModal={showDeleteModal}
        title={t('usersettings.modal.alert')}
        description={t('usersettings.modal.underlying-data-removed')}
        confirmationButton={t('usersettings.modal.confirm-button')!}
        cancelButton={t('usersettings.modal.cancel-button')}
        onConfirmDeletion={() => destroyItem(selectedLayerItem)}
        onCancelDeletion={() => setShowDeleteModal(false)}
        removeTrigger
      />
      <ErrorMessages
        errors={errorMessages}
        onCloseError={(index) => setErrorMessages(errorMessages.filter((_, i) => i !== index) as string[])}
      />
    </>
  );
};

export default LayerDataGrid;
