import { ComponentType, useEffect, FC } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import http from '../../Utils/HttpClient';
import Loader from '../../Shared/Components/Loader/Loader';

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component }) => {
  const loader = () => (
    <div className="centralize-loader">
      <Loader />
    </div>
  );
  const { getAccessTokenSilently } = useAuth0();

  // taken from: https://stackoverflow.com/a/68407340
  useEffect(() => {
    http.tokenGenerator = getAccessTokenSilently;
  }, [getAccessTokenSilently]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: loader,
  });

  return <Component />;
};

export default ProtectedRoute;
