import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { useAuth0 } from '@auth0/auth0-react';
import { editUser } from '../../Management/Data/UserManagerService';
import useTeam from '../../../Shared/Hooks/useTeam';
import IFilterData from '../../../Models/Interfaces/IFilterValue';
import useUser from '../../../Shared/Hooks/useUser';
import Button from '../../../Shared/Components/Button/Button';
import Modal from './Modal';
import Loader from '../../../Shared/Components/Loader/Loader';

import './UserSettings.scss';

type PropType = {
  redirectOnSave?: boolean;
};

const EditTeam = ({ redirectOnSave = false }: PropType) => {
  const navigator = useNavigate();
  const { userInfo, setUserInfo } = useUser();
  const { isLoading: teamsLoading, data: teams } = useTeam();
  const [userChoice, setUserChoice] = useState<string | null>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const { logout } = useAuth0();

  const { t } = useTranslation();

  const filterOptions: IFilterData[] = [];

  const changeTeam = async (newTeam: string) => {
    await editUser(userInfo!.userId!, { TeamId: newTeam });
    userInfo!.teamId = newTeam;
    localStorage.removeItem('user');
    setUserInfo(userInfo);

    if (redirectOnSave) {
      navigator('/');
    } else {
      setShowModal(!showModal);
    }
  };

  if (!teamsLoading) {
    teams!.forEach((filterOption) => {
      filterOptions.push({ value: filterOption.id, label: filterOption.name });
    });
  }

  useEffect(() => {}, [userInfo]);

  if (userInfo === undefined) {
    return <Loader />;
  }

  let teamIndex = filterOptions.findIndex((team) => team.value === userInfo?.teamId);

  if (teamIndex === -1) teamIndex = 0;

  return (
    <div className="content-container">
      <div className="user-settings">
        {!teamsLoading && (
          <>
            <h1>{t('usersettings.edit-team.title')}</h1>
            <p>{t('usersettings.edit-team.description')}</p>

            <Select
              defaultValue={filterOptions[teamIndex!]}
              classNamePrefix="react-select"
              className="team"
              options={filterOptions}
              onChange={(choice) => setUserChoice(choice!.value)}
            />
            <div className="button-container button-container--center">
              <Button
                disabled={!userChoice && userChoice !== userInfo?.teamId}
                onClick={() => changeTeam(userChoice!)}
                label={t('usersettings.edit-team.button')!}
              />
            </div>
          </>
        )}
      </div>
      <Modal
        showModal={showModal}
        modalTitle={t('usersettings.modal.relog-title')}
        modalDescription={t('usersettings.modal.relog-description')}
        modalButton={t('usersettings.modal.relog-button')}
        onCloseModal={() => {
          logout();
        }}
      />
    </div>
  );
};

export default EditTeam;
