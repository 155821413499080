import useBem from '@frontend/use-bem';

import DomainStatistic from './Model/DomainStatistic';

type PropType = Partial<DomainStatistic> & { rootClassName: string };

const DomainStatScore = ({ comparisonScore, score, showScore, rootClassName }: PropType) => {
  const { bemClassName, bemClassNames } = useBem(rootClassName);
  let newScore: number | undefined = score;
  let newComparisonScore: number | undefined = comparisonScore;

  if (score === 0 || score === undefined) newScore = 1;
  if (comparisonScore === 0 || comparisonScore === undefined) newComparisonScore = 1;

  return (
    <div className={bemClassNames([bemClassName('score'), bemClassName('score', 'hide-value', !showScore)])}>
      <div
        className={bemClassName('score-bar')}
        style={{ width: `${newScore! * 10}%` }}
        data-score={score}
      />
      {comparisonScore && (
        <div
          className={bemClassNames([bemClassName('score-bar'), bemClassName('score-bar', 'comparison')])}
          style={{ width: `${newComparisonScore! * 10}%` }}
          data-score={comparisonScore}
        />
      )}
    </div>
  );
};

export default DomainStatScore;
