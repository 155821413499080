import { AxiosResponse } from 'axios';

import Survey from '../../../Models/Survey/Survey';
import SurveySpecification from '../../../Models/Survey/SurveySpecification';
import http from '../../../Utils/HttpClient';
import SurveyPost from '../../../Models/Survey/SurveyPost';
import SurveyPostPublic from '../../../Models/Survey/SurveyPostPublic';
import { SurveyShortInfo } from '../../../Shared/Types/types';

const base = `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL_SURVEYS}/Surveys`;

const handleGetResult = (data: any) =>
  new Survey(
    data.Id,
    data.Active,
    data.CreatedDate,
    data.CustomerId,
    data.Description,
    data.EndDate,
    data.Responses,
    SurveySpecification.fromJson(data.SerializedSpecification),
    data.StartDate,
    data.Title,
  );

const get = async (surveyId: string) => {
  const { data } = await http.get(`${base}/${surveyId}`);
  return handleGetResult(data);
};

const getPublic = async (surveyId: string) => {
  const { data } = await http.get(`${base}/${surveyId}/PublicSurvey`);
  return handleGetResult(data);
};

const post = async (surveyId: string, answers: SurveyPost) => {
  const { data } = await http.post(`${base}/${surveyId}/SubmitResponse`, answers);
  return { data };
};

const getAllInfo = async (): Promise<SurveyShortInfo[]> => {
  const {
    data: { value },
  } = await http.get<any, AxiosResponse<{ value: SurveyShortInfo[] }>>(`${base}/Titles`);
  return value;
};

const postPublic = async (surveyId: string, answers: SurveyPostPublic) => {
  const { data } = await http.post(`${base}/${surveyId}/SubmitPublicResponse`, answers);
  return { data };
};

export { get, getAllInfo, getPublic, post, postPublic };
