import { createBrowserRouter } from 'react-router-dom';

import Auth0ProviderWithHistory from './Features/Auth/Auth0ProviderWithHistory';
import ProtectedRoute from './Features/Auth/ProtectedRoute';
import App from './App';
import DashboardPage from './Features/Dashboard/Pages/DashboardPage';
import DashboardPageEmployer from './Features/Dashboard/Pages/DashboardPageEmployer';
import AboutUsPage from './Features/About/Pages/AboutUsPage';
import WhatIsWellAtWorkPage from './Features/About/Pages/WhatIsWellAtWork';
import OurMission from './Features/About/Pages/OurMission';
import SurveyPage from './Features/Survey/Pages/SurveyPage';
import EditTeamPage from './Features/UserSettings/Pages/EditTeamPage';
import SetNicknamePage from './Features/UserSettings/Pages/SetNicknamePage';
import EditPasswordPage from './Features/UserSettings/Pages/EditPasswordPage';
import UploadUsersPage from './Features/UploadUsers/Pages/UploadUsersPage';
import CustomersManagementPage from './Features/Management/Pages/AdminManagementLayer';
import CustomerManagementLayer from './Features/Management/Pages/CustomerManagementLayer';
import BusinessUnitManagementLayer from './Features/Management/Pages/BusinessUnitManagementLayer';
import UnitManagementLayer from './Features/Management/Pages/UnitManagementLayer';
import NotFound from './Features/Error/Pages/NotFound';
import ErrorPage from './Features/Error/Pages/ErrorCatching';
import SignupPage from './Features/Signup/pages/SignupPage';
import SignupSuccesfulPage from './Features/Signup/pages/SignupSuccesfulPage';
import DeactivateAccountPage from './Features/UserSettings/Pages/DeactivateAccountPage';
import DivisionsManagementPage from './Features/Management/Pages/OrganisationManagementLayer';
import BusinessUnitsManagementPage from './Features/Management/Pages/DivisionManagementLayer';
import TeamManagementLayer from './Features/Management/Pages/TeamManagementLayer';
import PublicSurveyPage from './Features/Survey/Pages/PublicSurveyPage';
import PublicSurveyPageCompleted from './Features/Survey/Pages/PublicSurveyPageCompleted';

const protectedRoutes = [
  {
    path: '/dashboard',
    element: <DashboardPage />,
  },
  {
    path: '/hr-dashboard',
    element: <DashboardPageEmployer />,
  },
  {
    path: '/teamlead-dashboard',
    element: <DashboardPageEmployer />,
  },
  {
    path: '/over-ons',
    element: <AboutUsPage />,
  },
  {
    path: '/about-us',
    element: <AboutUsPage />,
  },
  {
    path: '/wat-is-well-at-work',
    element: <WhatIsWellAtWorkPage />,
  },
  {
    path: '/what-is-well-at-work',
    element: <WhatIsWellAtWorkPage />,
  },
  {
    path: '/onze-missie',
    element: <OurMission />,
  },
  {
    path: '/our-mission',
    element: <OurMission />,
  },
  {
    path: '/',
    element: <SurveyPage />,
  },
  {
    path: '/survey/wellbeing',
    element: <SurveyPage forceWellbeing />,
  },
  {
    path: '/survey/background',
    element: <SurveyPage forceBackground />,
  },
  {
    path: '/complete-profile',
    element: <EditTeamPage />,
  },
  {
    path: '/edit-nickname',
    element: <SetNicknamePage />,
  },
  {
    path: '/edit-password',
    element: <EditPasswordPage />,
  },

  {
    path: '/customers',
    element: <CustomersManagementPage />,
  },
  {
    path: '/backoffice/:customerId',
    element: <CustomerManagementLayer />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

if (window.__RUNTIME_CONFIG__.REACT_APP_CLIENT !== 'tno') {
  const extraProtectedRoutesWellAtWork = [
    {
      path: '/edit-team',
      element: <EditTeamPage />,
    },
    {
      path: '/backoffice/:customerId/:organisationId',
      element: <DivisionsManagementPage />,
    },
    {
      path: '/backoffice/:customerId/:organisationId/:divisionId',
      element: <BusinessUnitsManagementPage />,
    },
    {
      path: '/backoffice/:customerId/:organisationId/:divisionId/:businessUnitId',
      element: <BusinessUnitManagementLayer />,
    },
    {
      path: '/backoffice/:customerId/:organisationId/:divisionId/:businessUnitId/:unitId',
      element: <UnitManagementLayer />,
    },
    {
      path: '/backoffice/:customerId/:organisationId/:divisionId/:businessUnitId/:unitId/:teamId',
      element: <TeamManagementLayer />,
    },
    {
      path: '/upload-users/:customerId',
      element: <UploadUsersPage />,
    },
  ];

  protectedRoutes.push(...extraProtectedRoutesWellAtWork);
}

if (window.__RUNTIME_CONFIG__.REACT_APP_CLIENT === 'tno') {
  const extraProtectedRoutesTno = [
    {
      path: '/deactivate-account',
      element: <DeactivateAccountPage />,
    },
  ];

  protectedRoutes.push(...extraProtectedRoutesTno);
}

const router: {
  path: string;
  element: JSX.Element;
  children?: { path: string; element: JSX.Element }[] | undefined;
  errorElement?: JSX.Element | undefined;
}[] = [
  {
    path: '/',
    element: (
      <Auth0ProviderWithHistory>
        <ProtectedRoute component={App} />
      </Auth0ProviderWithHistory>
    ),
    children: protectedRoutes,
    errorElement: <ErrorPage />,
  },
  {
    path: '/survey/public/',
    element: <App />,
    children: [
      {
        path: '/survey/public/:id',
        element: <PublicSurveyPage />,
      },
      {
        path: '/survey/public/completed',
        element: <PublicSurveyPageCompleted />,
      },
    ],
  },
];

if (window.__RUNTIME_CONFIG__.REACT_APP_CLIENT === 'tno') {
  const tnoRoutes = [
    {
      path: '/signup/successful',
      element: <SignupSuccesfulPage />,
    },
    {
      path: '/signup/:signupToken',
      element: <SignupPage />,
    },
  ];

  router.push(...tnoRoutes);
}

export default createBrowserRouter(router);
